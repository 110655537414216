import { apiSlice } from "../../app/api/apiSlice";

export const formApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        createForm: builder.mutation({
            query: () => ({
                url: '/form/createForm',
                method: 'GET'
            })
        }),
        getClientsAndBundles: builder.mutation({
            query: (request) => ({
                url: `/form/${request.userId}/getClientsAndBundles/${request.fileDetailsId}`,
                method: 'GET'
            })
        }),
        generateForms: builder.mutation({
            query: (request) => ({
                url: '/form/generate',
                method: 'POST',
                body: {
                    ...request
                }
            })
        }),
        getForms: builder.mutation({
            query: (request) => ({
                url: `/form/${request.userId}/getForms/${request.formsId}`,
                method: 'GET'
            })
        }),
        updateForm: builder.mutation({
            query: (request) => {
                let formData = new FormData()
                formData.append('id', request.id)
                formData.append('userId', request.userId)
                formData.append('bundleId', request.bundleId)
                formData.append('fileName', request.fileName)
                formData.append('form', request.form, request.fileName)

                return {
                  url: '/form/updateForm',
                  method: 'POST',
                  body:  formData,
                  formData: true  
                }
              }
        }),
        getFormsByUserId: builder.mutation({
            query: (userId) => ({
                url: `/form/getFormsByUserId/${userId}`,
                method: 'GET'
            })
        }),
    })
})

export const { 
    useCreateFormMutation,
    useGetClientsAndBundlesMutation,
    useGenerateFormsMutation,
    useGetFormsMutation,
    useUpdateFormMutation,
    useGetFormsByUserIdMutation
} = formApiSlice