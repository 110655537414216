import { apiSlice } from "../../app/api/apiSlice"

export const reportsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getReportTemplates: builder.mutation({
            query: userId => ({
                url: '/report/getReportTemplates/' + userId,
                method: 'GET'
            })
        }),
        createReportTemplate: builder.mutation({
            query: request => ({
                url: '/report/createReportTemplate',
                method: 'POST',
                body: {
                    ...request
                }
            })
        }),
        getReportTemplateHeaders: builder.mutation({
            query: request => ({
                url: '/report/getReportTemplateHeaders',
                method: 'POST',
                body: {
                    ...request
                }
            })
        }),
        getFileHeaders: builder.mutation({
            query: (getHeadersRequest) => ({
                url: '/report/getFileHeaders/' + getHeadersRequest.id,
                method: 'POST',
                body: getHeadersRequest.userId
            })
        }),
        updateReportTemplate: builder.mutation({
            query: request => ({
                url: '/report/updateReportTemplate',
                method: 'POST',
                body: {
                    ...request
                }
            })
        }),
        deleteReportTemplate: builder.mutation({
            query: (deleteReportTemplateRequest) => ({
                url: '/report/deleteReportTemplate/' + deleteReportTemplateRequest.id,
                method: 'POST',
                body: deleteReportTemplateRequest.userId
            })
        }),
        createReport: builder.mutation({
            query: (createReportRequest) => ({
                url: '/report/createReport/' + createReportRequest.id,
                method: 'POST',
                body: createReportRequest.userId,
                responseHandler: ((response) => response.blob()),
                cache: "no-cache"
            })
        }),
    })
})

export const {
    useGetReportTemplatesMutation,
    useCreateReportTemplateMutation,
    useGetReportTemplateHeadersMutation,
    useGetFileHeadersMutation,
    useUpdateReportTemplateMutation,
    useDeleteReportTemplateMutation,
    useCreateReportMutation
} = reportsApiSlice