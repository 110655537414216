import { createSlice } from "@reduxjs/toolkit";

const supportStaffSlice = createSlice({
    name: 'supportStaff',
    initialState: {},
    reducers: {
        setSupportStaffAdvisors: (state, action) => {
            const connectedAdvisors = action.payload
            state.connectedAdvisors = connectedAdvisors
        }
    }
})

export const { setSupportStaffAdvisors } = supportStaffSlice.actions

export default supportStaffSlice.reducer

export const selectConnectedAdvisors = (state) => state?.supportStaff?.connectedAdvisors