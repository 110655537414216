import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setCredentials } from '../../features/auth/authSlice'

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        console.log(getState())
        const token = getState().auth.token
        console.log("token", token)
        if(token) {
            headers.set("Authorization", `Bearer ${token}`)
        }
        return headers
    }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
    console.log(args) //request url, method, body
    console.log(api) // signal, dispatch, getState()
    console.log(extraOptions) //custom like {shout: true}

    let result = await baseQuery(args, api, extraOptions)

    console.log(result?.error);
    if(result?.error?.status === 401 || result?.error?.status === 'FETCH_ERROR') {
        console.log('sending refresh token')
        api.dispatch(setCredentials({ user: null, role: null, token:null }))

        //send refresh token to get new access token
        const refreshResult = await baseQuery('/refresh', api, extraOptions)
        console.log("refreshResult", refreshResult)
        if(refreshResult?.data) {
            //store new token
            api.dispatch(setCredentials({ ...refreshResult.data }))

            //retry original query with new access token
            result = await baseQuery(args, api, extraOptions)
        } else {
            if(refreshResult?.error?.status === 401) {
                refreshResult.error.error = "Your login has expired."
            } else if (refreshResult?.error?.status === 'FETCH_ERROR') {
                console.log("NETWORK ERROR", refreshResult)
                refreshResult.error.error = "Network Server Error."
            }
            return refreshResult
        }
    }

    return result
}

//without auth headers
//  const authBaseQuery = fetchBaseQuery({ baseUrl: 'http://localhost:8080'})

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: ["File", "User", "Report", "ConnectedAdvisor"],
    endpoints: builder => ({})
})

// export const apiAuthSlice = createApi({
//     baseQuery: authBaseQuery,
//     tagTypes: ["File", "User"],
//     endpoints: builder => ({})
// })