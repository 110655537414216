import defaultAccountTypes from "./accountTypes/defaultAccountTypes.json"
import upAccountTypes from "./accountTypes/upAccountTypes.json"
import defaultFieldCategory from "./category/defaultFieldCategory.json"
import upFieldCategory from "./category/upFieldCategory.json"
import defaultFieldTypes from "./fieldTypes/defaultFieldTypes.json"
import upFieldTypes from "./fieldTypes/upFieldTypes.json"

export const getAccountTypes = (broker) => {
    let accountTypes = defaultAccountTypes
    
    if(broker === "UP") {
        accountTypes = upAccountTypes
    }

    Object.keys(accountTypes).forEach(accountPlatform => {
        accountTypes[accountPlatform].sort((a, b) =>{
            if (a < b) {
                return -1
            }
            if (a > b) {
                return 1
            }
            return 0
        })
    })

    return accountTypes
}

export const getFieldCategory = (broker) => {
    let fieldCategory = defaultFieldCategory
    
    if(broker === "UP") {
        fieldCategory = upFieldCategory
    }
    return fieldCategory
}

export const getFieldTypes = (broker) => {
    let fieldTypes = defaultFieldTypes
    
    if(broker === "UP") {
        fieldTypes = upFieldTypes
    }

    let accountTypes = fieldTypes["Account Type"].options
    accountTypes.sort((a, b) => {
        if (a.label < b.label) {
            return -1
        }
        if (a.label > b.label) {
            return 1
        }
        return 0
    })

    return fieldTypes
}

export const getCopyFieldList = (broker) => {
    let copyFieldList = [
        "Primary Address",
        "Primary City",
        "Primary State",
        "Primary Zip",
        "Tax Bracket",
        "Number of Dependents",
        "Annual Income",
        "Liquid Net Worth",
        "Net Worth",
        "General Investment Knowledge",
        "Stocks IE",
        "Bonds IE",
        "Mutual Funds IE",
        "Margin Trading IE",
        "Options Trading IE",
        "Annuities IE",
        "REITS / Alt IE",
        "Stocks",
        "Bonds",
        "Mutual Funds",
        "Variable Annuities",
        "Fixed Annuities",
        "Insurance",
        "Alternative Investments"]

    if(broker === "UP") {
        copyFieldList = [
            "Marital Status",
            "Resident State",
            "Phone",
            "Legal Address",
            "Legal City",
            "Legal State",
            "Legal Zip",
            "Client Rep Relationship",
            "Client Rep Years Known",
            "Annual Income",
            "Net Worth",
            "Liquid Assets",
            "Tax Bracket",
            "Years Experience Mutual Funds / ETFs",
            "Years Experience Annuities",
            "Years Experience Stocks",
            "Years Experience Bonds",
            "Years Experience Alternative",
            "Years Experience Other",
            "All current holdings ($) Mutual Funds / ETFs",
            "All current holdings ($) Annuities",
            "All current holdings ($) Stocks",
            "All current holdings ($) Bonds",
            "All current holdings ($) Alternative",
            "All current holdings ($) Other"
        ]
    }

    return copyFieldList
}