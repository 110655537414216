import "./Reusable.css"
import { Routes, Route } from 'react-router-dom'
import DashLayout from './components/DashLayout'
import Login from './features/auth/Login'
import FileUpload from './features/file/FileUpload'
import FileList from './features/file/FileList'
import FileDetails from './features/file/FileDetails'
import ManageUser from './features/user/ManageUser'
import Prefetch from './features/auth/Prefetch'
import RequireAuth from './features/auth/RequireAuth'
import PasswordChange from './features/user/PasswordChange'
import RequirePasswordChange from './features/auth/RequirePasswordChange'
import Logout from './features/auth/Logout'
import Require2FA from './features/auth/2fa/Require2FA'
import Verify2FA from './features/auth/2fa/Verify2FA'
import SupportStaff from './features/file/support/SupportStaff'
import Error403Page from './components/Error403Page'
import CustomReports from './features/report/CustomReports'
import CustomReportTemplate from './features/report/CustomReportTemplate'
import Forms from "./features/form/Forms"
import FormSelector from "./features/form/FormSelector"
import FileDetailsEditor from "./features/file/revamp/fileDetails/FileDetailsEditor"

function App() {
  return (
    <Routes>
      <Route index element={<Login />} />
        <Route element={<RequireAuth allowedRoles={['EMPLOYEE', 'ADVISOR', 'SUPPORT_STAFF']} />}>
          <Route path="logout" element={<Logout />} />
          <Route path="verify" element={<Verify2FA />} />
          <Route element={<Require2FA />}>
            <Route path="dash" element={<DashLayout />}>
              <Route path='error403' element={<Error403Page />} />
              <Route path="passwordChange" element={<PasswordChange />} />
              <Route element={<RequirePasswordChange />}>
                <Route path="fileDetails/:id" element={<FileDetailsEditor />} />
                <Route element={<RequireAuth allowedRoles={['EMPLOYEE']} />}>
                  <Route element={<Prefetch />}>
                    <Route index element={<FileUpload />} />
                    <Route path="fileList" element={<FileList />} />
                    <Route path="manageUser" element={<ManageUser />} />
                  </Route> 
                </Route>

                <Route element={<RequireAuth allowedRoles={['SUPPORT_STAFF']} />}>
                  <Route path="support" element={<SupportStaff />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={['ADVISOR']} />}>
                  <Route path="forms" element={<Forms />} />
                  <Route path="forms/clientForms/:id" element={<FormSelector />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={['SUPPORT_STAFF', 'ADVISOR']} />}>
                  <Route path="customReports" element={<CustomReports />} />
                  <Route path="customReports/:fileDetailsId/template/:templateId" element={<CustomReportTemplate />} />
                </Route>
              </Route>
            </Route>
          </Route>
      </Route>
    </Routes>
  )
}

export default App
