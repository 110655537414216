import { configureStore } from "@reduxjs/toolkit"
import { apiSlice } from "./api/apiSlice"
import { setupListeners } from "@reduxjs/toolkit/dist/query"
import componentReducer from "../components/componentSlice"
import  authReducer from "../features/auth/authSlice"
import fileReducer from "../features/file/fileSlice"
import supportStaffReducer from "../features/file/support/supportStaffSlice"
import reportReducer from "../features/report/reportSlice"
import formReducer from "../features/form/formSlice"

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        component: componentReducer,
        auth: authReducer,
        file: fileReducer,
        supportStaff: supportStaffReducer,
        report: reportReducer,
        form: formReducer
    },
    middleware: getDefaultMiddleware => 
        getDefaultMiddleware({serializableCheck: false}).concat(apiSlice.middleware),
    devTools: true
})

setupListeners(store.dispatch)