import { useState, useEffect } from 'react'
import { selectCurrentId } from "../authSlice"
import { useSelector } from "react-redux"
import { useGenerate2faQrCodeMutation } from '../authApiSlice'
import { useDispatch } from 'react-redux'
import { showSpinner, hideSpinner } from '../../../components/componentSlice'
import './Verify2FA.css'

const QrCode2FA = ({handleShowVerificationForm}) => {
    const [qrCode, setQrCode] = useState("")

    const id = useSelector(selectCurrentId)

    const [generate2faQrCode, { isLoading }] = useGenerate2faQrCodeMutation()
    const dispatch = useDispatch()

    useEffect(() => {
        if(isLoading) {
            dispatch(showSpinner())
        } else{
            dispatch(hideSpinner())
        }
    }, [isLoading])
    
    useEffect(() => {
        async function generateQrCode() {
            const response = await generate2faQrCode(id)
            setQrCode(response?.data?.qrCode)
        }

        if(id && !qrCode) {
            generateQrCode()
        }
    }, [])

    return (
        <>
            {
                !isLoading &&   
                <>
                    <div className='top-header'>
                        <header>Scan QR Code</header>
                    </div>
                    <div className='input-field'>
                        <p>
                            Scan this Barcode using Google Authenticator app on your phone 
                            to use it later in login.
                        </p>
                    </div>
        
                    <div className='qrCode'>
                        <img src={`data:image/jpg;base64, ${qrCode}`}  />
                    </div>
                    
                    <div className='input-field'>
                        <p>
                            Note: You will only do this on the first login.
                        </p>
                    </div>
                    <div className='input-field'>
                        <button type="button" onClick={() => handleShowVerificationForm(true)}>Log In</button>
                    </div>
                </>
            }
        </>
    )
}
export default QrCode2FA