import { Outlet, Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { selectCurrentRole, isUserPasswordTemp } from "../features/auth/authSlice"
import { selectFile } from "../features/file/fileSlice"

import "./DashLayout.css"

const DashLayout = () => {
    const role = useSelector(selectCurrentRole)
    const isPasswordTemp = useSelector(isUserPasswordTemp)
    const file = useSelector(selectFile)

    return (
        <>
            <nav className="welcome-nav">
                <div className="logo">Reserve CRM</div>
                <div className="nav-items">
                    {
                        role === "EMPLOYEE" &&
                        <>
                            <Link to="/dash">
                                <div className="nav-item">Upload</div>
                            </Link>
                            <Link to="/dash/fileList">
                                <div className="nav-item">Clients & Accounts</div>
                            </Link>
                            <Link to="/dash/manageUser">
                                <div className="nav-item">Manage User</div>
                            </Link>
                        </>
                    }
                    {
                        role === "ADVISOR" &&
                        <>
                            <Link to={"/dash/fileDetails/" + file?.id}>
                                <div className="nav-item">Clients & Accounts</div>
                            </Link>
                            <Link to={"/dash/forms"}>
                                <div className="nav-item">Forms</div>
                            </Link>
                        </>
                    }
                    {
                        role === "SUPPORT_STAFF" &&
                        <Link to="/dash/support">
                            <div className="nav-item">Advisors</div>
                        </Link>
                    }
                    {
                        (role === "SUPPORT_STAFF" || role === "ADVISOR") &&
                        <Link to="/dash/customReports">
                            <div className="nav-item">Reports</div>
                        </Link>
                    }
                    <Link to="/logout">
                        <button className="logout">Logout</button>
                    </Link>
                </div>
            </nav>
            <div className="dash-container">
                <Outlet />  
            </div>
        </>
    )
}
export default DashLayout