import { useState, useEffect } from 'react'
import { isUsing2FA, isVerified2FA, selectCurrentUser } from "../authSlice"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import './Verify2FA.css'
import QrCode2FA from './QrCode2FA'
import Verify2faForm from './Verify2faForm'

const Verify2FA = () => {
    const [showVerificationForm, setShowVerificationForm] = useState(true)
    const isUserUsing2FA = useSelector(isUsing2FA)
    const verified = useSelector(isVerified2FA)
    const user = useSelector(selectCurrentUser)
    const navigate = useNavigate()

    
    useEffect(() => {
        if(!isUserUsing2FA) {
            setShowVerificationForm(false)
        }
    }, [isUserUsing2FA])

    useEffect(() => {
        if(verified && user) {
            navigate("/logout")
        }
    }, [])

    return (
        <>
            <nav className="login-welcome-nav">
                <div className="login-logo">Reserve CRM</div>
            </nav>
            <div className="login-box">
                <div className='login-container'>
                    {
                        (!isUserUsing2FA && !showVerificationForm) &&
                            <QrCode2FA handleShowVerificationForm={setShowVerificationForm} />
                    }
                    {
                        showVerificationForm && 
                            <Verify2faForm />
                    }
                </div>
            </div>
        </>
    )
}
export default Verify2FA