import { store } from '../../app/store'
import { filesApiSlice } from '../file/fileApiSlice'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

const Prefetch = () => {
    useEffect(() => {
        const files = store.dispatch(filesApiSlice.endpoints.getFiles.initiate())

        return () => {
            files.unsubscribe()
        }
    }, [])
    return <Outlet />
}

export default Prefetch