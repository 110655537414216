import { useState, useEffect } from "react"
import { useDispatch } from 'react-redux'
import { useCreateUserMutation, useGetUserRolesMutation } from "./userApiSlice"
import { showSpinner, hideSpinner } from "../../components/componentSlice"
import { message } from 'antd'
import PasswordValidator from "./PasswordValidator"
import { isValidPassword } from "./utils/PasswordUtil"
import SupportStaffAdvisorPicker from "./SupportStaffAdvisorPicker"

const CreateUser = () => {

    const [username, setUsername] = useState(null)
    const [password, setPassword] = useState(null)
    const [confirmPassword, setConfirmPassword] = useState(null)
    const [role, setRole] = useState(null)
    const [broker, setBroker] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [userRoles, setUserRoles] = useState([])
    const [connectedAdvisors, setConnectedAdvisors] = useState([])

    const [messageApi, contextHolder] = message.useMessage()
    const dispatch = useDispatch()

    const [ createUser,  {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useCreateUserMutation()

    const [ getUserRoles,  {
    }] = useGetUserRolesMutation()

    useEffect(() => {
        if(isLoading) {
            dispatch(showSpinner())
        } else{
            dispatch(hideSpinner())
        }
    }, [isLoading])

    useEffect(() => {
        if(isSuccess) {
            setUsername("")
            setRole("")
            setPassword("")
            setConfirmPassword("")
            setBroker(null)
            setConnectedAdvisors([])
            alertMessage("User created")
        }
    }, [isSuccess])

    useEffect(() => {
        if(isError) {
            console.log(error)
            if(error?.status === "FETCH_ERROR") {
                setErrorMessage("No Server Response")
            } else {
                setErrorMessage(error?.data)
            }
        }
    }, [isError])

    useEffect(() => {
        async function handleGetUserRoles() {
          const response = await getUserRoles()
          setUserRoles(response.data)
        }
        handleGetUserRoles()
    }, [])

    const alertMessage = (message) => {
        messageApi.success(message);
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setErrorMessage("")
        if(!username) {
            setErrorMessage("Username is required.")
            return
        }

        if(!role) {
            setErrorMessage("Role is required.")
            return
        }

        if(role === "ADVISOR" && !broker) {
            setErrorMessage("Broker/Dealer is required.")
            return
        }

        if(!password) {
            setErrorMessage("Password is required.")
            return
        }

        if(!isValidPassword(password)) {
            return
        }

        if(password !== confirmPassword) {
            setErrorMessage("Password does not match")
            return
        }
        
        await createUser({
            username, 
            password, 
            role, 
            broker: role === "ADVISOR" ? broker : null, 
            connectedAdvisors: role === "SUPPORT_STAFF" ? connectedAdvisors : null})
    }

    const createUserRoleOptions = () => {
        return userRoles.map(role => <option value={role.value}>{role.description}</option>)
    }

    const handleAddAdvisor = (advisor) => {
        let advisors = [...connectedAdvisors]
        advisors.push(advisor)
        setConnectedAdvisors(advisors)
    }

    const handleRemoveAdvisor = (id) => {
        let advisors = [...connectedAdvisors]
        let updated = advisors.filter(advisor => id !== advisor.id)

        setConnectedAdvisors(updated)
    }

    return(
        <div className="create-user">
            {contextHolder}
            <div className='top-header'>
                <header>New User</header>
            </div>
            {errorMessage && 
                <div className="errorMessage">
                    <p>{errorMessage}</p>
                </div>
            }
            <form onSubmit={(e) => onSubmit(e)}>
                <div className='input-field'>
                    <input required className='input' placeholder='Username' value={username} onChange={(e) => setUsername(e.target.value)} />
                </div>
                <div className='input-field'>
                    <select value={role} onChange={(e) => setRole(e.target.value)} > 
                        <option disabled selected value="">User Role</option>
                        {createUserRoleOptions()}
                    </select>
                </div>
                {
                    role === "ADVISOR" &&
                    <div className='input-field'>
                        <select value={broker} onChange={(e) => setBroker(e.target.value)} > 
                            <option disabled selected value="">Broker/Dealer</option>
                            <option value="Default">Default</option>
                            <option value="UP">UP</option>
                        </select>
                    </div>
                }

                {
                    role === "SUPPORT_STAFF" &&
                    <SupportStaffAdvisorPicker 
                        handleAddAdvisor={handleAddAdvisor} 
                        handleRemoveAdvisor={handleRemoveAdvisor}
                        updatedConnectedAdvisors={connectedAdvisors} 
                    />
                }

                <div className='input-field'>
                    <input required className='input' placeholder='Password' type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <PasswordValidator password={password} />
                <div className='input-field'>
                    <input required className='input' placeholder='Confirm Password' type='password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                </div>
                <div className='input-field'>
                    <button type="submit">Create User</button>
                </div>
            </form>
        </div>
    )
}

export default CreateUser