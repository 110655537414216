export const isMailingAddressField = (field) => {
    return ["Mailing Address", 
            "Mailing City", 
            "Mailing State", 
            "Mailing Zip"].includes(field)
}

export const isTrustedContactField = (field) => {
    return field.toLowerCase().includes("trusted contact")
}

export const isAccountMissingValues = (account) => {
    let isMissingValues = false
    Object.keys(account).forEach(field => {
        if(!isMissingValues) {
            isMissingValues = account[field]?.value == null || !account[field]?.value.trim()
        }
    })
    return isMissingValues
}

export const isJointFieldIncluded = (accountfields) => {
    return accountfields.includes("Joint First Name") && 
        accountfields.includes("Joint Last Name") && 
        accountfields.includes("Joint Social Security Number")
}