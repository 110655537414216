import "./CustomReports.css"
import { DeleteFilled, DownOutlined } from '@ant-design/icons'
import { Dropdown, Space, message } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { selectCurrentId, selectCurrentRole } from "../auth/authSlice"
import { useGetReportTemplatesMutation, useDeleteReportTemplateMutation, useCreateReportMutation } from "./reportApiSlice"
import { useEffect, useState } from "react"
import { showSpinner, hideSpinner } from "../../components/componentSlice"
import { useNavigate } from 'react-router-dom'
import { selectReportTemplates, setReportTemplateList } from "./reportSlice"
import { downloadFile } from "../utils/Utilities"
import SearchableDropdown from "../../components/SearchableDropdown"

const CustomReports = () => {
    const id = useSelector(selectCurrentId)
    const role = useSelector(selectCurrentRole)
    const reportTemplateList = useSelector(selectReportTemplates)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [messageApi, contextHolder] = message.useMessage()

    const [reportTemplates, setReportTemplates] = useState([])
    const [errorMessage, setErrorMessage] = useState('')
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [templateSearchValue, setTemplateSearchValue] = useState('')
    
    const [ getReportTemplates,  {
            isLoading,
            isError,
            error
     }] = useGetReportTemplatesMutation()

     const [ deleteReportTemplate,  {
        isSuccess: isDeleteSuccess,
        isLoading: isDeleteLoading,
        isError: isDeleteError,
        error: deleteError
    }] = useDeleteReportTemplateMutation()

    const [ createReport,  {
        isSuccess: isCreateReportSuccess,
        isLoading: isCreateReportLoading,
        isError: isCreateReportError,
        error: createReportError
    }] = useCreateReportMutation()

    const successMessage = (message) => {
        messageApi.success(message)
    }

    const infoMessage = (message) => {
        messageApi.info(message)
    }

    useEffect(() => {
        if(isDeleteSuccess) {
            successMessage("Template deleted.")
        }
    }, [isDeleteSuccess])
    
    useEffect(() => {
        async function handleGetReportTemplates() {
            const response = await getReportTemplates(id).unwrap()
            if(response) {
                dispatch(setReportTemplateList(response))
                setReportTemplates(response)
            }
        }
        handleGetReportTemplates()
    }, [id])

    useEffect(() => {
        if(reportTemplateList) {
            setReportTemplates(reportTemplateList)
        } 
    }, [reportTemplateList])

    useEffect(() => {
        if(isLoading && !reportTemplateList) {
            dispatch(showSpinner())
        } else{
            dispatch(hideSpinner())
        }
    }, [isLoading])

    useEffect(() => {
        if(isCreateReportLoading) {
            dispatch(showSpinner())
        } else{
            dispatch(hideSpinner())
        }
    }, [isCreateReportLoading])

    const handleMenuClick = (key, fileDetailsId, templateId) => {
        if(key === 'E') {
            navigate(`/dash/customReports/${fileDetailsId}/template/${templateId}`)
        } else if(key === 'D') {
            handleDeleteTemplate(fileDetailsId, templateId)
        }
    }

    const handleDeleteTemplate = async (fileDetailsId, templateId) => {
        if(isDeleteLoading) {
            return
        }

        if(!fileDetailsId || !templateId) {
            return
        }

        setSelectedTemplate(null)
        setTemplateSearchValue('')

        infoMessage("Deleting template.")
        let isSuccess = await deleteReportTemplate({id: templateId, userId: id}).unwrap()
        
        if(isSuccess) {
            removeTemplate(fileDetailsId, templateId)
        }
    }

    const removeTemplate = (fileDetailsId, templateId) => {
        let updatedReportTemplates = []
        reportTemplates.forEach(reportTemplate => {
            let updatedTemplates = reportTemplate.templates
            if(reportTemplate.fileDetailsId === fileDetailsId) {
                updatedTemplates = reportTemplate.templates.filter(template => template.id !== templateId)
            }
            updatedReportTemplates.push({...reportTemplate, templates: updatedTemplates})
        })

        setReportTemplates(updatedReportTemplates)
        dispatch(setReportTemplateList(updatedReportTemplates))
    }

    const createModificationButton = (fileDetailsId, templateId) => {
        let items = [
            {
                key: 'E',
                label: 'Edit',
            },
            {
                key: 'D',
                danger: true,
                label: 'Delete',
            }
        ]
        return (
            <Dropdown
                menu={{
                items,
                onClick: (e) => handleMenuClick(e.key, fileDetailsId, templateId)
                }}
            >
                <a onClick={(e) => e.preventDefault()}>
                <Space>
                    <DownOutlined />
                </Space>
                </a>
            </Dropdown>
        )
    }

    const handleCreateReport = async () => {
        if(!selectedTemplate) {
            return
        }

        try {
            const data = await createReport({id: selectedTemplate, userId: id}).unwrap()
            console.log(data)
    
            downloadFile(data, templateSearchValue + ".xlsx")
        } catch(err) {
            if(err.status === "FETCH_ERROR") {
                setErrorMessage("No Server Response");
            } else if(err.status === 400) {
                setErrorMessage("Error exporting file");
            } else if(err.status === 401) {
                setErrorMessage("Unauthorized");
            } else {
                setErrorMessage(err.data?.message);
            }
        }
    }

    const getOptions = () => {
        if(reportTemplates) {
            let options = []
            reportTemplates.forEach(reportTemplate => {
                reportTemplate.templates.forEach(template => {
                    let name = role !== 'ADVISOR' ? template.templateName + "-" + reportTemplate.advisor : template.templateName
                    name = name + `(${template.templateType})`
                    options.push({id: template.id, name})
                })
            })
            return options
        }
    }

    const onChangeSearch = (value, id) => {
        setSelectedTemplate(id)
        setTemplateSearchValue(value)
    }

    const getTable = () => {
        return (
            reportTemplates && reportTemplates.map(report => {
                return (
                    <div className="file-table-container">
                        <div className="file-table-header">
                            <header>{role !== 'ADVISOR' ? 'Advisor: ' + report.advisor : ''}</header>
                            <div className='file-table-header-field'>
                                <button onClick={() => navigate(`/dash/customReports/${report.fileDetailsId}/template/new`)}>New</button>
                            </div>
                        </div>
                        {
                            report.templates && report.templates.length > 0 ?
                                <table className="file-table">
                                    <tr>
                                        <th></th>
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th>Last Updated</th>
                                    </tr>
                                    {
                                        report.templates.map(template => {
                                            return (
                                                <tr>
                                                    <td>{createModificationButton(report.fileDetailsId, template.id)}</td>
                                                    <td>{template.templateName}</td>
                                                    <td>{template.templateType}</td>
                                                    <td>{template.lastUpdated}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </table>
                            :
                                <table className="file-table">
                                    <tr>
                                        <td>No template created yet...</td>
                                    </tr>
                                </table>        
                        }
                    </div>
                )
            })
        )
    }

    return (
        <div className="table-page-container">
            {contextHolder}
            {errorMessage && 
                <div className="support-error">
                    <p>{errorMessage}</p>
                </div>
            }
            <div className="custom-report-header">
                <div className='top-header'>
                    <header>Custom Reports</header>
                </div>
            </div>
            <div className="create-report">
                <div className="search-dropdown">
                    <SearchableDropdown
                        options={getOptions()}
                        label="name"
                        id="id"
                        selectedVal={templateSearchValue}
                        placeholderVal={"Select Template..."}
                        handleChange={(val, id) => onChangeSearch(val, id)}
                    />
                </div>
                <div className='custom-report-field'>                    
                    <button onClick={() => handleCreateReport()} disabled={!selectedTemplate}>Generate Report</button>
                </div>
            </div>
            {getTable()}
        </div>
    )
}

export default CustomReports