import "./FileDetailsEditor.css"
import FileDetailsControls from "./FileDetailsControls"
import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { selectCurrentId } from "../../../auth/authSlice"
import { useDispatch } from "react-redux"
import { showSpinner, hideSpinner } from "../../../../components/componentSlice"
import { useGetFileDetailsMutation, useGetFileClientAndAccountsInfoMutation, useUpdateFileClientAndAccountsInfoMutation, useDeleteClientAccountMutation, useCreateClientAccountMutation } from "./fileDetailsApiSlice"
import { useParams } from "react-router-dom"
import FileDetailsInputs from "./FileDetailsInputs"
import { message, Modal } from 'antd'
import FileClientAccountCreator from "./FileClientAccountCreator"

const FileDetailsEditor = () => {
    const { id } = useParams()
    const userId = useSelector(selectCurrentId)

    const [messageApi, contextHolder] = message.useMessage()
    
    const [clientDetailsAndRows, setClientDetailsAndRows] = useState([])
    const [clientAccountsInfos, setClientAccountsInfos] = useState(null)
    const [selectedClient, setSelectedClient] = useState(null)
    const [broker, setBroker] = useState("Default")
    const [errorMessage, setErrorMessage] = useState(null)
    const [isCreateClientAccountModalOpen, setCreateClientAccountModalOpen] = useState(false)

    const dispatch = useDispatch()

    const [getFileDetails, { isLoading }] = useGetFileDetailsMutation()
    const [getFileClientAndAccountsInfo, { isLoading: isGetClientAndAccountsInfoLoading }] = useGetFileClientAndAccountsInfoMutation()
    const [updateFileClientAndAccountsInfo, { isLoading: isUpdateFileClientAndAccountsInfoLoading }] = useUpdateFileClientAndAccountsInfoMutation()
    const [deleteClientAccount, { isLoading: isDeleteClientAccountLoading }] = useDeleteClientAccountMutation()
    const [createClientAccount, { isLoading: isCreateClientAccountLoading }] = useCreateClientAccountMutation()

    useEffect(() => {
        async function handleGetFileDetails() {
            try {
                const response = await getFileDetails({fileDetailsId: id, userId: userId}).unwrap()
                if(response) {
                    setClientDetailsAndRows(response.clientDetailsAndRows)
                    setBroker(response.brokerType)
                }
            } catch(err) {
                if(!err.status || err.status === "FETCH_ERROR") {
                    setErrorMessage("No Server Response")
                } else {
                    setErrorMessage(err.data?.detail)
                }
            }
        }
        if(userId) {
            handleGetFileDetails()
        }
    }, [userId])

    useEffect(() => {
        if(isLoading || isGetClientAndAccountsInfoLoading || isDeleteClientAccountLoading || isCreateClientAccountLoading) {
            dispatch(showSpinner())
        } else{
            dispatch(hideSpinner())
        }
    }, [isLoading, isGetClientAndAccountsInfoLoading, isDeleteClientAccountLoading, isCreateClientAccountLoading])

    const getClientOptions = () => {
        return clientDetailsAndRows.map(client => ({id: client, name: client.clientDetails.firstName + " " + client.clientDetails.lastName}))
    }

    const handleSelectedClientChange = async (client) => {
        setErrorMessage(null)
        try {
            setSelectedClient(client.clientDetails)
            let request = {
                fileDetailsId: id, 
                userId: userId,
                clientDetailsAndRows: client
            }
            const response = await getFileClientAndAccountsInfo(request).unwrap()
            if(response) {
                setClientAccountsInfos(response)
            }
        } catch(err) {
            if(!err.status || err.status === "FETCH_ERROR") {
                setErrorMessage("No Server Response")
            } else {
                setErrorMessage(err.data?.detail)
            }
        }
    }

    const showSuccessfulAlert = (message) => {
        messageApi.success(message)
    }

    const showInfoAlert = (message) => {
        messageApi.info(message)
    }

    const handleUpdate = async (isAutoUpdate) => {
        setErrorMessage(null)

        if(isAutoUpdate) {
            showInfoAlert("Auto updating...")
        }

        try {
            let request = {
                fileDetailsId: id, 
                userId: userId,
                clientAndAccountInfos: clientAccountsInfos
            }
            await updateFileClientAndAccountsInfo(request).unwrap()
            updateSelectedClientDetails(clientAccountsInfos.clientInfos)
            showSuccessfulAlert("Updated!")
        } catch(err) {
            if(!err.status || err.status === "FETCH_ERROR") {
                setErrorMessage("No Server Response")
            } else {
                setErrorMessage(err.data?.detail)
            }
        }
    }

    const updateSelectedClientDetails = (updatedClientInfos) => {
        const inputFirstName =  getClientFieldValue(updatedClientInfos, "First Name")
        const inputLastName =  getClientFieldValue(updatedClientInfos, "Last Name")
        const inputSsn =  getClientFieldValue(updatedClientInfos, "Social Security Number")

        let isSelectedClientDetailsUpdated =  (inputFirstName !== selectedClient.firstName ||
                                                inputLastName !== selectedClient.lastName ||
                                                inputSsn !== selectedClient.socialSecurityNumber)    

        if(!isSelectedClientDetailsUpdated) {
            return
        }

        let updatedClientDetailsAndRows = clientDetailsAndRows.map(details => {
            let updatedDetails = details

            if(updatedDetails.clientDetails.firstName === selectedClient.firstName &&
                updatedDetails.clientDetails.lastName === selectedClient.lastName &&
                updatedDetails.clientDetails.socialSecurityNumber === selectedClient.socialSecurityNumber) {
                    updatedDetails = {...updatedDetails, clientDetails: {
                        firstName: inputFirstName,
                        lastName: inputLastName,
                        socialSecurityNumber: inputSsn
                    }}
                }
            return updatedDetails
        })
        setClientDetailsAndRows(updatedClientDetailsAndRows)
        setSelectedClient({
            firstName: inputFirstName,
            lastName: inputLastName,
            socialSecurityNumber: inputSsn
        })
    }

    const getClientFieldValue = (clientInfos, fieldName) => {
        return clientInfos.filter(info => info.fieldName === fieldName)[0]?.value
    }

    const handleDeleteClientAccount = async (rowsToDelete) => {
        setErrorMessage(null)
        try {
            let request = {
                fileDetailsId: id, 
                userId: userId,
                rows: rowsToDelete
            }
            await deleteClientAccount(request).unwrap()
            removeRowsFromClientAccountsInfos(rowsToDelete)
            removeRowsFromClientDetailsAndRows(rowsToDelete)
            showSuccessfulAlert("Deleted!")
        } catch(err) {
            if(!err.status || err.status === "FETCH_ERROR") {
                setErrorMessage("No Server Response")
            } else {
                setErrorMessage(err.data?.detail)
            }
        }
    }

    const removeRowsFromClientAccountsInfos = (rowsToDelete) => {
        let filteredAccounts = clientAccountsInfos.accounts.filter(account => !rowsToDelete.includes(account.rowNum))
        if(filteredAccounts.length > 0) {
            setClientAccountsInfos(prev => ({...prev, accounts: filteredAccounts}))
        } else {
            setClientAccountsInfos(null)
            setSelectedClient(null)
        }
    }

    const removeRowsFromClientDetailsAndRows = (rowsToDelete) => {
        let updatedClientDetailsAndRows = clientDetailsAndRows.map(details => {
            let updatedDetails = details

            if(updatedDetails.clientDetails.firstName === selectedClient.firstName &&
                updatedDetails.clientDetails.lastName === selectedClient.lastName &&
                updatedDetails.clientDetails.socialSecurityNumber === selectedClient.socialSecurityNumber) {
                    updatedDetails = {...updatedDetails, rows: updatedDetails.rows.filter(row => !rowsToDelete.includes(row))}
                }
            return updatedDetails
        })

        let filteredClientDetailsAndRows = updatedClientDetailsAndRows.filter(detail => detail.rows !== undefined && detail.rows.length > 0)

        setClientDetailsAndRows(filteredClientDetailsAndRows)
    }

    const handleCreateClientAccount = async (clientDetails, accountPlatformAndTypeList) => {
        setErrorMessage(null)

        let existingClientDetailsAndRows = clientDetailsAndRows.filter(detail => detail.clientDetails.firstName === clientDetails.firstName &&
            detail.clientDetails.lastName === clientDetails.lastName &&
            detail.clientDetails.socialSecurityNumber === clientDetails.socialSecurityNumber)[0]

        let requestClientDetailsAndRows = existingClientDetailsAndRows ? existingClientDetailsAndRows : {clientDetails, rows: []}

        try {
            let request = {
                fileDetailsId: id, 
                userId: userId,
                clientDetailsAndRows: requestClientDetailsAndRows,
                accountPlatformAndTypeList: accountPlatformAndTypeList
            }
            const response = await createClientAccount(request).unwrap()
            if(response) {
                setSelectedClient(clientDetails)
                setClientAccountsInfos(response)
                addOrReplaceClientDetailsAndRows(clientDetails, response.accounts.map(account => account.rowNum))
                setCreateClientAccountModalOpen(false)
                showSuccessfulAlert("Created!")
            }
        } catch(err) {
            if(!err.status || err.status === "FETCH_ERROR") {
                setErrorMessage("No Server Response")
            } else {
                setErrorMessage(err.data?.detail)
            }
        }
    }

    const addOrReplaceClientDetailsAndRows = (clientDetails, rows) => {
        let updatedClientDetailsAndRows = clientDetailsAndRows.filter(detail => !(detail.clientDetails.firstName === clientDetails.firstName &&
            detail.clientDetails.lastName === clientDetails.lastName &&
            detail.clientDetails.socialSecurityNumber === clientDetails.socialSecurityNumber))

            updatedClientDetailsAndRows = [...updatedClientDetailsAndRows, {clientDetails, rows}]
        setClientDetailsAndRows(updatedClientDetailsAndRows)
    }

    return (
        <div className="file-details-page-container">
            {contextHolder}
            {errorMessage && 
                <div className="error-message">
                    <p>{errorMessage}</p>
                </div>
            }
            <FileDetailsControls clientOptions={getClientOptions()} handleSelectedClientChange={handleSelectedClientChange} selectedClient={selectedClient} broker={broker} setCreateClientAccountModalOpen={setCreateClientAccountModalOpen} />
            <FileDetailsInputs clientAccountsInfos={clientAccountsInfos} 
                                clientOptions={getClientOptions()} 
                                broker={broker} 
                                setClientAccountsInfos={setClientAccountsInfos}
                                handleUpdate={handleUpdate}
                                selectedClient={selectedClient}
                                isUpdating={isUpdateFileClientAndAccountsInfoLoading}
                                handleDeleteClientAccount={handleDeleteClientAccount}
            />
             <Modal open={isCreateClientAccountModalOpen} onCancel={() => setCreateClientAccountModalOpen(false)} footer={[]}>
                <FileClientAccountCreator 
                    broker={broker} 
                    selectedClient={selectedClient}
                    handleCreateClientAccount={handleCreateClientAccount}
                />
            </Modal>
        </div>
    )
}

export default FileDetailsEditor