import React from 'react';

import styled from "styled-components";
import {rgba} from "polished";
import MuiCircularProgress from '@material-ui/core/CircularProgress';
import { isLoading } from './componentSlice';
import { useSelector } from "react-redux";

const CircularProgress = styled(MuiCircularProgress)`
    &.MuiCircularProgress-colorPrimary {
        color: dark-blue;
    }
`;

const SpinnerOverlay = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* stylelint-disable-line scale-unlimited/declaration-strict-value */ /* Stop form labels appearing above overlay */
    background: ${rgba('#FFFFFF', 0.8)};
`;

const Spinner = () => {
    const showSpinner = useSelector(isLoading);

    return (
        showSpinner &&
        <SpinnerOverlay>
            <CircularProgress />
        </SpinnerOverlay>
    );
}

export default Spinner