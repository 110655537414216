import { createSlice } from "@reduxjs/toolkit";

const reportSlice = createSlice({
    name: 'report',
    initialState: {},
    reducers: {
        setReportTemplateList: (state, action) => {
            const reportTemplateList = action.payload
            state.reportTemplateList = reportTemplateList
        }
    }
})

export const { setReportTemplateList } = reportSlice.actions

export default reportSlice.reducer

export const selectReportTemplates = (state) => state?.report?.reportTemplateList