import { createSlice } from "@reduxjs/toolkit"

const formSlice = createSlice({
    name: 'form',
    initialState: {},
    reducers: {
        setFormsList: (state, action) => {
            const formsList = action.payload
            state.formsList = formsList
        }
    }
})

export const { setFormsList } = formSlice.actions

export default formSlice.reducer

export const selectFormsList = (state) => state?.form?.formsList