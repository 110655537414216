import { useState, useEffect } from "react"
import { useGetLockedUsersMutation, useResetUserMutation } from "./userApiSlice"
import { useDispatch } from 'react-redux'
import { showSpinner, hideSpinner } from "../../components/componentSlice"
import SearchableDropdown from "../../components/SearchableDropdown"
import { message } from 'antd'
import PasswordValidator from "./PasswordValidator"
import { isValidPassword } from "./utils/PasswordUtil"

const ResetUser = () => {

    const [selectedLockedUser, setSelectedLockedUser] = useState(null)
    const [lockedUsers, setLockedUsers] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [resetPassword, setResetPassword] = useState(null)
    const [confirmResetPassword, setConfirmResetPassword] = useState(null)
    const [resetErrorMessage, setResetErrorMessage] = useState(null)

    const [messageApi, contextHolder] = message.useMessage()
    const dispatch = useDispatch()

    const [ getLockedUsers,  {
        isLoading: isLockedUsersLoading,
        isSuccess: isLockedUsersSuccess,
        isError: isLockedUsersError,
        error: lockedUsersError
    }] = useGetLockedUsersMutation()
    
    const [ resetUser,  {
        isLoading: isResetUserLoading,
        isSuccess: isResetUserSuccess,
        isError: isResetUserError,
        error: resetUserError
    }] = useResetUserMutation()

    useEffect(() => {
        if(isResetUserError) {
            console.log(resetUserError)
            if(resetUserError?.status === "FETCH_ERROR") {
                setResetErrorMessage("No Server Response")
            } else {
                setResetErrorMessage(resetUserError?.data)
            }
        }
    }, [isResetUserError])

    const alertMessage = (message) => {
        messageApi.success(message);
    }

    useEffect(() => {
        if(isResetUserSuccess) {
            setResetPassword("")
            setConfirmResetPassword("")

            let updatedLockedUsers = lockedUsers.filter(user => user.username != selectedLockedUser)
            setLockedUsers(updatedLockedUsers)
            setSearchValue("")
            setSelectedLockedUser(null)
            alertMessage("User reset successful")
        }
    }, [isResetUserSuccess])


    useEffect(() => {
        if(isResetUserLoading) {
            dispatch(showSpinner())
        } else{
            dispatch(hideSpinner())
        }
    }, [isResetUserLoading])

    useEffect(() => {
        async function getLockedUserList() {
          const response = await getLockedUsers()
          setLockedUsers(response.data)
        }
        getLockedUserList()
    }, [])

    const onUserReset = async (e) => {
        e.preventDefault()
        setResetErrorMessage("")

        if(!resetPassword) {
            setResetErrorMessage("Password is required.")
            return
        }

        if(!isValidPassword(resetPassword)){
            return
        }

        if(resetPassword !== confirmResetPassword) {
            setResetErrorMessage("Password does not match")
            return
        }

        await resetUser({password: resetPassword, id: selectedLockedUser})
    }

    const getLockedUsersOptions = () => {
        return lockedUsers?.map(user => ({id: user.id, name: user.username}))
    }

    const onChangeSearch = (value, id) => {
        if(id) {
            setSelectedLockedUser(id)
        }
        setSearchValue(value)
    }

    return (
        <div className="reset-user">
            {contextHolder}
            <div className='top-header'>
                <header>Reset User</header>
            </div>
            {resetErrorMessage && 
                <div className="errorMessage">
                    <p>{resetErrorMessage}</p>
                </div>
            }
            <div className="reset-body">
                <div className="reset-search">
                    <SearchableDropdown
                        options={getLockedUsersOptions()}
                        label="name"
                        id="id"
                        placeholderVal={"Select user..."}
                        selectedVal={searchValue}
                        handleChange={(val, id) => onChangeSearch(val, id)}
                    />
                </div>
                {
                    selectedLockedUser &&
                    <form onSubmit={(e) => onUserReset(e)}>
                        <div className='input-field'>
                            <input required className='input' type='password' placeholder='Temp Password' value={resetPassword} onChange={(e) => setResetPassword(e.target.value)} />
                        </div>
                        <PasswordValidator password={resetPassword} />
                        <div className='input-field'>
                            <input required className='input' type='password' placeholder='Confirm Temp Password' value={confirmResetPassword} onChange={(e) => setConfirmResetPassword(e.target.value)} />
                        </div>
                        <div className='input-field'>
                            <button type="submit">Reset</button>
                        </div>
                    </form>
                }
            </div>
        </div>
    )
}

export default ResetUser