export const hasAtLeast8Characters = (password) => {
    return password?.length >= 8
}

export const hasUpperCaseCharacter = (password) => {
    return password && (password !== password.toLowerCase())
}

export const hasLowerCaseCharacter = (password) => {
    return password && (password !== password.toUpperCase())
}

export const hasNumber = (password) => {
    return /\d/.test(password)
}

export const hasSpecialCharacter = (password) => {
    let format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return format.test(password)
}

export const isValidPassword = (password) => {
    return hasAtLeast8Characters(password) && hasUpperCaseCharacter(password) && hasLowerCaseCharacter(password) && hasNumber(password) && hasSpecialCharacter(password)
}
