import { createSlice } from "@reduxjs/toolkit";

const fileSlice = createSlice({
    name: 'file',
    initialState: {},
    reducers: {
        setFile: (state, action) => {
            const file = action.payload
            state.file = file
        }
    }
})

export const { setFile } = fileSlice.actions

export default fileSlice.reducer

export const selectFile = (state) => state?.file?.file