import SearchableDropdown from "../../../../components/SearchableDropdown"

const FileDetailsControls = ({ handleSelectedClientChange, clientOptions, selectedClient, setCreateClientAccountModalOpen }) => {
    const onSelectedClientChange = (client) => {
        if(!client) {
            return
        }
        handleSelectedClientChange(client)
    }

    return (
        <div className="file-details-controls">
            <div className="control">
                <h3 className="header">Client</h3>
                <div className="control-main">
                    <div className="search-dropdown">
                        <SearchableDropdown
                            options={clientOptions}
                            label="name"
                            id="id"
                            selectedVal={selectedClient ? selectedClient.firstName + " " + selectedClient.lastName : null}
                            placeholderVal={"Select Client..."}
                            handleChange={(val, id) => onSelectedClientChange(id)}
                        />
                    </div>
                    <div className="control-button">
                        <button onClick={() => setCreateClientAccountModalOpen(true)}>Create Account</button>
                    </div>
                </div>
            </div>
            <div className="control">
                <h3 className="header">Copy Household</h3>
                <div className="control-main">
                    <div className="search-dropdown">
                        <SearchableDropdown
                            options={clientOptions}
                            label="name"
                            id="id"
                            // selectedVal={selectedBundle?.name}
                            placeholderVal={"Select Client..."}
                            // handleChange={(val, id) => setSelectedBundle(id)}
                        />
                    </div>
                    <div className="control-button">
                        <button>Copy</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FileDetailsControls