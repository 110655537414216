import { apiSlice } from "../../app/api/apiSlice";

export const usersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAdvisorUsers: builder.mutation({
            query: () => ({
                url: '/user/getAdvisorUsers'
            })
        }),
        createUser: builder.mutation({
            query: user => ({
                url: '/user/createUser',
                method: 'POST',
                body: {
                    ...user
                }
            })
        }),
        updatePassword: builder.mutation({
            query: updatePasswordRequest => ({
                url: '/user/updatePassword/' + updatePasswordRequest.id,
                method: 'POST',
                body: updatePasswordRequest.password
            })
        }),
        getLockedUsers: builder.mutation({
            query: () => ({
                url: '/user/getLockedUsers'
            })
        }),
        resetUser: builder.mutation({
            query: resetUserRequest => ({
                url: '/user/resetUser/' + resetUserRequest.id,
                method: 'POST',
                body: resetUserRequest.password
            })
        }),
        getUserRoles: builder.mutation({
            query: () => ({
                url: '/user/role/getUserRoles'
            })
        }),
        getSupportStaffUsers: builder.mutation({
            query: () => ({
                url: '/user/supportStaff/getSupportStaffUsers'
            })
        }),
        updateConnection: builder.mutation({
            query: updateConnectionRequest => ({
                url: '/user/supportStaff/updateConnection/' + updateConnectionRequest.id,
                method: 'POST',
                body: updateConnectionRequest.connections
            })
        }),
        getConnectedAdvisors: builder.mutation({
            query: id => ({
                url: '/user/supportStaff/getConnectedAdvisors/' + id
            })
        }),
    })
})

export const {
    useGetAdvisorUsersMutation,
    useCreateUserMutation,
    useUpdatePasswordMutation,
    useGetLockedUsersMutation,
    useResetUserMutation,
    useGetUserRolesMutation,
    useGetSupportStaffUsersMutation,
    useUpdateConnectionMutation,
    useGetConnectedAdvisorsMutation
} = usersApiSlice