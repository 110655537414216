import { useState } from "react"
import { getAccountTypes } from "../../template/TemplateHelper"
import "./FileClientAccountCreator.css"

const FileClientAccountCreator = ({ broker, selectedClient, handleCreateClientAccount }) => {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [socialSecurityNumber, setSocialSecurityNumber] = useState("")
    const [accountList, setAccountList] = useState([{accountPlatform: "", accountType: ""}])
    const [errorMessage, setErrorMessage] = useState(null)

    const onSubmit = async (e) => {
        e.preventDefault()
        setErrorMessage("")
        let clientDetails = {
            firstName: firstName?.trim(),
            lastName: lastName?.trim(),
            socialSecurityNumber: socialSecurityNumber?.trim()
        }
        handleCreateClientAccount(clientDetails, accountList)
        resetForm()
    }

    const resetForm = () => {
        setFirstName("")
        setLastName("")
        setSocialSecurityNumber("")
        setAccountList([{accountPlatform: "", accountType: ""}])
    }

    const handleAccountChange = (e, index) => {
        let updatedAccountList = [...accountList]
        let updatedAccount  = {
            accountType: "",
            accountPlatform: e.target.value
        }

        updatedAccountList[index] = updatedAccount

        setAccountList(updatedAccountList)
    }

    const handleAccountTypeChange = (e, index) => {
        let updatedAccountList = [...accountList]
        updatedAccountList[index]["accountType"] = e.target.value

        setAccountList(updatedAccountList)
    }

    const getAccountOptions = () => {
        let accountTypes = getAccountTypes(broker)
        return Object.keys(accountTypes).map(key => <option value={key}>{key}</option>)
    }

    const getAccountTypeOptions = (accountPlatform) => {
        let accountTypes = getAccountTypes(broker)
        return accountTypes[accountPlatform]?.map(key => <option value={key}>{key}</option>)
    }

    const addAccount = () => {
        let updatedAccountList = [...accountList]
        updatedAccountList.push({
            accountPlatform: "", 
            accountType: ""
        })

        setAccountList(updatedAccountList)
    }

    const deleteAccount = () => {
        let updatedAccountList = [...accountList]
        updatedAccountList.splice(-1)

        setAccountList(updatedAccountList)
    }

    const applyCurrentClientInfo = () => {
        if(selectedClient) {
            setFirstName(selectedClient.firstName)
            setLastName(selectedClient.lastName)
            setSocialSecurityNumber(selectedClient.socialSecurityNumber)
        }
    }

    return(
        <div className="create-client">
            <div className='top-header'>
                <header>Create Account</header>
            </div>
            {errorMessage && 
                <div className="errorMessage">
                    <p>{errorMessage}</p>
                </div>
            }
            <form onSubmit={(e) => onSubmit(e)}>
                <div className='input-field'>
                    <h3>Client</h3>
                </div>
                <div className='input-field'>
                    <input required className='input' placeholder='First Name' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                </div>
                <div className='input-field'>
                    <input required className='input' placeholder='Last Name' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                </div>
                <div className='input-field'>
                    <input className='input' placeholder='Social Security Number' value={socialSecurityNumber} onChange={(e) => setSocialSecurityNumber(e.target.value)} />
                </div>
                <div className="create-client-button">
                    <button type="button" onClick={() => applyCurrentClientInfo()}>Use current client</button>
                </div>

                {
                    accountList.map((account, index) => {
                        let accountPlatform = account["accountPlatform"]
                        let accountType = account["accountType"]

                        let isFirstAccount = index === 0
                        let isLastAccount = accountList.length === index + 1
                        return (
                            <>
                                <div className='input-field'>
                                    <h3>Account {accountList.length > 1 && index + 1}</h3>
                                </div>
                                <div className='input-field'>
                                    <select required value={accountPlatform} onChange={(e) => handleAccountChange(e, index)} > 
                                        <option disabled selected value="">Account Platform</option>
                                        {getAccountOptions()}
                                    </select>
                                </div>
                                {(getAccountTypes(broker)[accountPlatform] && getAccountTypes(broker)[accountPlatform].length > 0) &&
                                    <div className='input-field'>
                                        <select required value={accountType} onChange={(e) => handleAccountTypeChange(e, index)} > 
                                            <option disabled selected value="">Account Type</option>
                                            {getAccountTypeOptions(accountPlatform)}
                                        </select>
                                    </div>
                                }
                                <div className="create-client-button">
                                    {(!isFirstAccount && isLastAccount)  && <button type="button" onClick={() => deleteAccount()}>Delete</button>}
                                    {isLastAccount && <button type="button" onClick={() => addAccount()}>Add</button>}
                                </div>
                            </>
                        )
                    })
                }
                <div className='input-field'>
                    <button type="submit">Create Account</button>
                </div>
            </form>
        </div>
    )
}

export default FileClientAccountCreator