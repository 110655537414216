import "./FileList.css";
import { useGetFilesQuery, useDeleteFileMutation, useExportFileMutation } from "./fileApiSlice"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { showSpinner, hideSpinner } from "../../components/componentSlice"
import { Popconfirm, message } from 'antd'
import { downloadFile } from "../utils/Utilities"
import { selectCurrentId } from "../auth/authSlice"

const FileList = () => {
    const [fileList, setFileList] = useState([])
    const [errorMessage, setErrorMessage] = useState("")
    const userId = useSelector(selectCurrentId)

    const {
        data,
        isLoading,
   } = useGetFilesQuery(undefined, {
       pollingInterval: 60000,
       refetchOnFocus: true,
       refetchOnMountOrArgChange: true
   })

   const [exportFile, {
    isLoading: isExportLoading,
}] = useExportFileMutation()

   useEffect(() => {
        if(data) {
            setFileList(data.entities)
        }
    }, [data])

   const [deleteFile, {
        isSuccess: isDeleteSuccess,
        isLoading: isDeleteLoading,
   }] = useDeleteFileMutation()

   const [messageApi, contextHolder] = message.useMessage()

   const alertMessage = (message) => {
       messageApi.success(message)
   }

   useEffect(() => {
        if(isDeleteSuccess) {
            alertMessage("Deleted.")
        }
    }, [isDeleteSuccess])

   useEffect(() => {
        if(isLoading || isDeleteLoading || isExportLoading) {
            dispatch(showSpinner())
        } else{
            dispatch(hideSpinner())
        }
    }, [isLoading, isDeleteLoading, isExportLoading])   

   const navigate = useNavigate()
   const dispatch = useDispatch()

   const handleDeleteFile = async (id, fileName) => {
    setErrorMessage("")
    if(isDeleteLoading) {
        return
    }
    try {
        const isSuccess = await handleExportFile(id, fileName)
        if(isSuccess) {
            await deleteFile({id, userId}).unwrap()
            let updatedFileList = {...fileList}
            delete updatedFileList[id]
            setFileList(updatedFileList)
        }
    } catch(err) {
        if(err.status === "FETCH_ERROR") {
            setErrorMessage("No Server Response")
        } else if(err.status === 400) {
            setErrorMessage("Error deleting file")
        } else if(err.status === 401) {
            setErrorMessage("Unauthorized")
        } else {
            setErrorMessage(err.data)
        }
    }
   }

   const handleExportFile = async (id, fileName) => {
    setErrorMessage("")
    if(isExportLoading) {
        return
    }
    try {
        const data = await exportFile({id, userId}).unwrap()
        console.log(data)

        downloadFile(data, fileName)
        return true
    } catch(err) {
        if(err.status === "FETCH_ERROR") {
            setErrorMessage("No Server Response");
        } else if(err.status === 400) {
            setErrorMessage("Error exporting file");
        } else if(err.status === 401) {
            setErrorMessage("Unauthorized");
        } else {
            setErrorMessage(err.data?.message);
        }
    }
}

   const getTableData = () => {
    return fileList && Object.values(fileList).length > 0 ?
        Object.values(fileList).map(entity => {
            return (
            <tr>
                <td>{entity.originalFileName}</td>
                <td>{entity.advisorName}</td>
                <td>{entity.lastUpdatedBy + " - " + entity.lastUpdatedByRole}</td>
                <td>{entity.lastUpdated}</td>
                <td>
                    <button className="table-button" onClick={() => navigate(`/dash/fileDetails/${entity.id}`)}>&gt;&gt;</button>
                </td>
                <td>
                    <Popconfirm
                        title="Delete"
                        description="Are you sure to delete this?"
                        onConfirm={() => handleDeleteFile(entity.id, entity.originalFileName)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <button className="table-delete-button">X</button>
                    </Popconfirm>
                </td>
            </tr>
            )
        })
        :
        <p>No available files</p> 
   }

    return (
        <div className="table-page-container">
            {contextHolder}
            {errorMessage && 
                <div className="errorMessage">
                    <p>{errorMessage}</p>
                </div>
            }
            <div className="table-container">
                <table className="file-table">
                    <tr>
                        <th>File Name</th>
                        <th>Advisor</th>
                        <th>Last Updated By</th>
                        <th>Last Updated</th>
                        <th></th>
                        <th></th>
                    </tr>
                    {getTableData()}
                </table>
            </div>
        </div>
    )
}

export default FileList