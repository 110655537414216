import Checkbox from "antd/es/checkbox/Checkbox"
import { CaretDownFilled, EditOutlined } from "@ant-design/icons"

import { useState, useRef, useEffect } from "react"
import { getFieldCategory } from "../../template/TemplateHelper"
import { isMailingAddressField, isTrustedContactField } from "../../util/FileFieldUtil"
import InputField from "./InputField"
import FileHeaderShortcuts from "./FileHeaderShortcuts"
import { Button, Popconfirm } from 'antd'

const FileDetailsInputs = ({ clientAccountsInfos, clientOptions, broker, handleUpdate, setClientAccountsInfos, selectedClient, isUpdating, handleDeleteClientAccount }) => {

    const [clientOrAccountsHeaderValue, setClientOrAccountsHeaderValue] = useState("CI")
    const [currentInactiveSection, setCurrentInactiveSection] = useState([])
    const [showOnlyMissing, setShowOnlyMissing] = useState(false)
    const [showMailingAddress, setShowMailingAddress] = useState(false)
    const [showTrustedContact, setShowTrustedContact] = useState(false)
    const [isInfoDirty, setIsInfoDirty] = useState(false)

    const updateFileRef = useRef(null)

    useEffect(() => {
        setClientOrAccountsHeaderValue("CI")
    }, [selectedClient])

    useEffect(() => {
        const doAutoUpdate = async () => {
            if(clientAccountsInfos && isInfoDirty) {
                handleUpdate(true)
                setIsInfoDirty(false)
            }
        }

        const interval = setInterval(doAutoUpdate, 3000)
        return () => clearInterval(interval)
    }, [clientAccountsInfos, isInfoDirty])

    const getInputFilterSwitch = () => {
        return (
            <div className="settings-checkbox">
                <table>
                    <tr>
                        <td><Checkbox value={showOnlyMissing} checked={showOnlyMissing} onChange={() => setShowOnlyMissing(!showOnlyMissing)} /></td>
                        <td><label>Only missing info</label></td>
                    </tr>
                </table>
                
                {
                    clientOrAccountsHeaderValue === "CI" &&
                    <>
                        <table>
                            <tr>
                                <td><Checkbox value={showMailingAddress} checked={showMailingAddress} onChange={() => setShowMailingAddress(!showMailingAddress)} /></td>
                                <td><label>Mailing address</label></td>
                            </tr>
                        </table>
                        <table>
                            <tr>
                                <td><Checkbox value={showTrustedContact} checked={showTrustedContact} onChange={() => setShowTrustedContact(!showTrustedContact)} /></td>
                                <td><label>Trusted contact</label></td>
                            </tr>
                        </table>
                    </>
                }
            </div>
        )
    }

    const handleClientOrAccountsHeaderSelect = (value) => {
        // setErrorMessage("")
        setClientOrAccountsHeaderValue(value)
        setCurrentInactiveSection([])
    }

    const onInputChange = (clientOrAccountIndex, name, value) => {
        setIsInfoDirty(true)
        if(clientOrAccountIndex === "CI") {
            let updatedClientInfos = clientAccountsInfos.clientInfos.map(infoField => {
                let updatedInfoField = infoField
                if(infoField.fieldName === name) {
                    updatedInfoField = {...infoField, value: value}
                }
                return updatedInfoField
            })

            setClientAccountsInfos((prevClientAccountsInfos) => ({...prevClientAccountsInfos, clientInfos: updatedClientInfos}))
        } else {
            let updatedAccounts = clientAccountsInfos.accounts.map((account, index) => {
                let updatedAccount = account
                if(index == clientOrAccountIndex) {
                    let updatedAccountInfos = account.accountInfos.map(infoField => {
                        let updatedInfoField = infoField
                        if(infoField.fieldName === name) {
                            updatedInfoField = {...infoField, value: value}
                        }
                        return updatedInfoField
                    })
                    updatedAccount = {...updatedAccount, accountInfos: updatedAccountInfos}
                }
                return updatedAccount
            })
            setClientAccountsInfos((prevClientAccountsInfos) => ({...prevClientAccountsInfos, accounts: updatedAccounts}))
        }
    }

    const createClientOrAccountsHeaderSelect = () => {
        return (
            <select value={clientOrAccountsHeaderValue} onChange={(e) => handleClientOrAccountsHeaderSelect(e.target.value)}>
                <option value="CI">Client Information</option>
                {
                    clientAccountsInfos?.accounts &&
                        clientAccountsInfos.accounts.map((account, index) => {
                            let accountInfos = account.accountInfos
                            if(!accountInfos) {
                                return
                            }

                            let isMissingValues = accountInfos.reduce((acc, curr) => acc || !curr.value, false)
                            let accountType = accountInfos.filter(field => field.fieldName === "Account Type")[0]?.value || ""
                            let accountNumber = accountInfos.filter(field => field.fieldName === "Account Number")[0]?.value || ""

                            return <option value={index}>{accountType + " - " + accountNumber + (isMissingValues ? " *" : "")}</option>
                        })
                }
            </select>
        )
    }

    const handleSectionToggle = (e, section) => {
        e.preventDefault()

        let updatedCurrentInactiveSection = [...currentInactiveSection]
        if(updatedCurrentInactiveSection.includes(section)) {
            const index = updatedCurrentInactiveSection.indexOf(section);
            if (index > -1) {
                updatedCurrentInactiveSection.splice(index, 1)
            }
        } else {
            setCurrentInactiveSection(updatedCurrentInactiveSection.push(section))
        }
        setCurrentInactiveSection(updatedCurrentInactiveSection)
    }

    const createInputs = () => {
        let inputs = clientOrAccountsHeaderValue === "CI" ? clientAccountsInfos?.clientInfos : clientAccountsInfos?.accounts[clientOrAccountsHeaderValue]?.accountInfos
        if(!inputs) {
            return
        }

        if(showOnlyMissing) {
            inputs = inputs.filter(input => input.value == null || !input.value?.toString().trim())
        }

        if(clientOrAccountsHeaderValue === "CI") {
            if(!showMailingAddress) {
                inputs = inputs.filter(input => !isMailingAddressField(input.fieldName))
            }

            if(!showTrustedContact) {
                inputs = inputs.filter(input => !isTrustedContactField(input.fieldName))
            }
        }

        let categorySection = {}
        inputs.forEach(input => {
            let field = <InputField infoField={input} 
                                    broker={broker}
                                    handleInputChange={onInputChange} 
                                    clientOrAccountsHeaderValue={clientOrAccountsHeaderValue} 
                                    clientOptions={clientOptions} 
                        />
            let fieldName = input.fieldName?.trim()

            let isOthers = true
            let fieldCategory = getFieldCategory(broker)
            Object.keys(fieldCategory).forEach(header => {
                if(fieldCategory[header].includes(fieldName)) {
                    let section = categorySection[header] ? categorySection[header] : []
                    section.push(field)
                    categorySection[header] = section  
                    isOthers = false
                }
            })
            
            if(isOthers) {
                let section = categorySection["Other Information"] ? categorySection["Other Information"] : []
                section.push(field)
                categorySection["Other Information"] = section  
            }
        })

        const onUpdate = (e) => {
            e.preventDefault()
            handleUpdate()
            setIsInfoDirty(false)
        }

        return (
            <div>
                {/* {contextHolder} */}
                {/* {
                    isJointFieldIncluded(Object.keys(inputs)) && 
                    <div className="joint-alert">
                        <Alert message="Note: You may only input the first and last name of a client. If the client is existing, after updating, the other details will be automatically filled." 
                            type="info" showIcon closable />
                    </div>
                }
                {formErrorList && formErrorList.length > 0 && <FileDetailsError errorList={formErrorList} />} */}
                
                <form className="input-form" onSubmit={(e) => onUpdate(e)}>
                    {
                        inputs && inputs.length > 0 ?
                            <>
                                {
                                    Object.keys(categorySection).map((header, index) => {
                                        return(
                                            categorySection[header] && categorySection[header].length > 0 && 
                                                <div className={"category-section" + (currentInactiveSection.includes(index) ? "" : " expanded")} id={header}>
                                                    <h3 className="category-title">
                                                        <button className="category-btn" type="button" onClick={(e) => handleSectionToggle(e, index)}>
                                                            <span className="span h5">{header}</span>
                                                            <CaretDownFilled  className="icon" />
                                                        </button>
                                                    </h3>

                                                    <div className="user-inputs">
                                                        {categorySection[header].map(sectionField => sectionField)}
                                                    </div>
                                                </div>
                                        )
                                    })
                                }
                            </>
                        :
                            <p>No info needed...</p>
                    }
                    <button style={{visibility: "hidden"}} type="submit" ref={updateFileRef}></button>
                </form>
            </div>
        )
    }

    const getRowsToDelete = () => {
        let rows = []
        if(clientOrAccountsHeaderValue === 'CI') {
            rows = clientAccountsInfos.accounts.map(account => account.rowNum)
        } else {
            rows = [...rows, clientAccountsInfos.accounts[clientOrAccountsHeaderValue]?.rowNum]
        }
        return rows
    }

    const deleteClientAccount = () => {
        handleDeleteClientAccount(getRowsToDelete())
        setClientOrAccountsHeaderValue("CI")
    }

    return (
        <div className="file-details-inputs">
            <div className="file-shortcuts">
                <FileHeaderShortcuts
                    clientAccountsInfos={clientAccountsInfos}
                    clientOrAccountsHeaderValue={clientOrAccountsHeaderValue}
                    handleClientOrAccountsHeaderSelect={handleClientOrAccountsHeaderSelect}
                    broker={broker}
                 />
            </div>
            <div className="inputs-container">
                <div className="inputs-header">
                    <div className="inputs-header-client-account">
                        {createClientOrAccountsHeaderSelect()}
                        {selectedClient &&
                            <div>
                                <Popconfirm
                                    title="Delete"
                                    description={`Are you sure to delete this ${clientOrAccountsHeaderValue === 'CI' ? 'client' : 'account'}?`}
                                    onConfirm={() => deleteClientAccount()}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <button className="delete-client-account-button">{`Delete ${clientOrAccountsHeaderValue === 'CI' ? 'Client' : 'Account'}`}</button>
                                </Popconfirm>
                            </div>
                        }
                    </div>
                    {getInputFilterSwitch()}
                </div>
                {createInputs()}
                {clientAccountsInfos &&
                    <div className="input-form-button">
                        <Button loading={isUpdating} onClick={() => updateFileRef.current.click()}>
                            <span>{isUpdating ? "Updating" : "Update"}</span>
                            <EditOutlined  />
                        </Button>
                    </div>
                }
            </div>
        </div>
    )
}

export default FileDetailsInputs