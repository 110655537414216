import { createSlice } from "@reduxjs/toolkit";

const componentSlice = createSlice({
    name: 'component',
    initialState: {isLoading: false},
    reducers: {
        showSpinner: (state, action) => {
            state.isLoading = true
        },
        hideSpinner: (state, action) => {
            state.isLoading = false
        }
    }
})

export const { showSpinner, hideSpinner } = componentSlice.actions

export default componentSlice.reducer

export const isLoading = (state) => state?.component?.isLoading