import { useLocation, Navigate, Outlet } from "react-router-dom"
import { selectCurrentUsername, selectCurrentRole } from "./authSlice"
import { useSelector, useDispatch } from "react-redux"
import { useRefreshMutation } from "./authApiSlice"
import { useEffect, useState } from "react"
import { showSpinner, hideSpinner } from "../../components/componentSlice"
import { useGetFileByAdvisorNameMutation } from "../file/fileApiSlice"
import { setFile } from "../file/fileSlice"

const RequireAuth = ({ allowedRoles }) => {
    const [trueSuccess, setTrueSuccess] = useState(false)
    const location = useLocation()
    const username = useSelector(selectCurrentUsername)
    const role = useSelector(selectCurrentRole)
    const dispatch = useDispatch()

    const [refresh, {
        isUninitialized,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useRefreshMutation()

    const [getFileByAdvisorName, { isLoading: isGetFileLoading }] = useGetFileByAdvisorNameMutation()

    useEffect(() => {
        if(isLoading) {
            dispatch(showSpinner())
        } else{
            dispatch(hideSpinner())
        }
    }, [isLoading])

    useEffect(() => {
        const verifyRefreshToken = async () => {
            try {
                await refresh()
                setTrueSuccess(true)
            } catch(err) {
                console.log(err)
            }
        }

        if(!username) {
            verifyRefreshToken()
        }
    }, [])

    useEffect(() => {
        const getAdvisorFile = async () => {
            try {
                if(role === "Advisor") {
                    const file = await getFileByAdvisorName(username).unwrap()
                    if(file) {
                        dispatch(setFile(file))
                    }
                }
            } catch(err) {
                console.log(err)
            }
        }

        if(trueSuccess && role && username) {
            getAdvisorFile()
        }
    }, [trueSuccess])
    
    let content
    if((isSuccess && trueSuccess) || (username && role)) {
        content = allowedRoles.includes(role) ? <Outlet /> : <Navigate to="/" state={{ from: location }} replace />
    } else if(isError) {
        content = <Navigate to="/" state={{ from: location }} replace />
    }

    return content
}

export default RequireAuth