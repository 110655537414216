import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { store } from './app/store';
import { Provider } from 'react-redux';
import Spinner from './components/Spinner';
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter(
  createRoutesFromElements(
      <Route path="/*" element={<App />} />
  )
)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Spinner />
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
