import "./CustomReportTemplate.css"
import { Checkbox, message } from 'antd'
import { Flex, Radio } from 'antd';
import { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { selectCurrentId, selectCurrentRole } from "../auth/authSlice"
import { getFieldCategory } from "../file/template/TemplateHelper"
import { showSpinner, hideSpinner } from "../../components/componentSlice"
import { useCreateReportTemplateMutation, useGetReportTemplateHeadersMutation, useGetFileHeadersMutation, useUpdateReportTemplateMutation } from "./reportApiSlice"
import { selectReportTemplates, setReportTemplateList } from "./reportSlice"


const CustomReportTemplate = () => {
    const { fileDetailsId, templateId } = useParams()
    const id = useSelector(selectCurrentId)
    const role = useSelector(selectCurrentRole)
    const reportTemplateList = useSelector(selectReportTemplates)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [messageApi, contextHolder] = message.useMessage()

    const [advisor, setAdvisor] = useState(null)
    const [accountHeaders, setAccountHeaders] = useState([])
    const [clientHeaders, setClientHeaders] = useState([])
    const [headerCheckers, setHeaderCheckers] = useState([])
    const [errorMessage, setErrorMessage] = useState([])
    const [templateName, setTemplateName] = useState('')
    const [templateType, setTemplateType] = useState('ACCOUNT')

    const successMessage = (message) => {
        messageApi.success(message)
    }

    const infoMessage = (message) => {
        messageApi.info(message)
    }


    const [ getFileHeaders,  {
                isLoading,
                isError,
                error
    }] = useGetFileHeadersMutation()

    const [ createReportTemplate, {
        isLoading: isCreateTemplateLoading,
        isSuccess: isCreateTemplateSuccess,
        isError: isCreateTemplateError,
        error: createTemplateError
    }] = useCreateReportTemplateMutation()

    const [ getReportTemplateHeaders, {
        isLoading: isReportTemplateHeadersLoading,
        isSuccess: isReportTemplateHeadersSuccess,
        isError: isReportTemplateHeadersError,
        error: reportTemplateHeadersError
    }] = useGetReportTemplateHeadersMutation()

    const [ updateReportTemplate, {
        isLoading: isUpdateReportTemplateLoading,
        isSuccess: isUpdateReportTemplateSuccess,
        isError: isUpdateReportTemplateError,
        error: updateReportTemplateError
    }] = useUpdateReportTemplateMutation()

    useEffect(() => {
        if(isLoading || isCreateTemplateLoading|| isReportTemplateHeadersLoading || isUpdateReportTemplateLoading) {
            dispatch(showSpinner())
        } else{
            dispatch(hideSpinner())
        }
    }, [isLoading, isCreateTemplateLoading, isReportTemplateHeadersLoading, isUpdateReportTemplateLoading])

    useEffect(() => {
        if(isCreateTemplateSuccess) {
            navigate(`/dash/customReports`)
        }

    }, [isCreateTemplateSuccess])

    useEffect(() => {
        if(isUpdateReportTemplateSuccess) {
            successMessage("Updated.")
        }

    }, [isUpdateReportTemplateSuccess])

    useEffect(() => {
        async function handleGetFileHeaders() {
            if(templateId === 'new') {
                const response = await getFileHeaders({id: fileDetailsId, userId: id }).unwrap()
                if(response) {
                    setAdvisor(response.advisor)

                    let accountHeaderChecker = createCategories(
                        response.accountHeaders.map(header => ({"name": header, "checked": false})),
                        response.brokerType
                    )
                    let clientHeaderChecker = createCategories(
                        response.clientHeaders.map(header => ({"name": header, "checked": false})),
                        response.brokerType
                    )
                    setAccountHeaders(accountHeaderChecker)
                    setClientHeaders(clientHeaderChecker)
                    
                    let defaultHeaders = templateType === 'ACCOUNT' ? accountHeaderChecker : clientHeaderChecker
                    setHeaderCheckers(defaultHeaders)
                }
            } else {
                const response = await getReportTemplateHeaders({userId: id, templateId: templateId}).unwrap()
                if(response) {
                    setTemplateName(response.templateName)
                    setTemplateType(response.templateType)
                    setAdvisor(response.advisor)
                    
                    let categories = createCategories(
                        [...response.headers],
                        response.brokerType
                    )
                    setHeaderCheckers(categories)
                }
            }
        }
        handleGetFileHeaders()
    }, [])

    const createCheckers = () => {
        return (
            headerCheckers && 
                Object.keys(headerCheckers)
                    .sort((a, b) => a === (templateType === "ACCOUNT" ? "Account Information" : "Contact Information") ? -1 : b === "Other Information" ? -1 : 0)
                    .map((category) => {
                        return (
                            <div className="category-card">
                                <div className="category-card-header">
                                    <header>{category}</header>
                                    <Checkbox checked={isSelectedAll(category)} onChange={(e) => onCategorySelectAll(e, category)}>Select all</Checkbox>
                                </div>
                                {
                                    headerCheckers[category] && headerCheckers[category].length > 0 && 
                                    <div className="category-headers">
                                        {
                                            headerCheckers[category].map(header => <Checkbox checked={header.checked} onChange={(e) => onCheckerChange(e, category, header.name)}>{header.name}</Checkbox>)
                                        }
                                    </div>
                                }
                            </div> 
                        )
                    })
        )
    }

    const createCategories = (checkers, brokerType) => {
        let categories = {}
        let fieldCategory = getFieldCategory(brokerType)
        checkers?.forEach(header => {
            let isOthers = true
            Object.keys(fieldCategory).forEach(category => {
                if(fieldCategory[category].includes(header.name?.trim())) {
                    let section = categories[category] ? categories[category] : []
                    section.push(header)
                    categories[category] = section  
                    isOthers = false
                }
            })

            if(isOthers) {
                let section = categories["Other Information"] ? categories["Other Information"] : []
                section.push(header)
                categories["Other Information"] = section  
            }
        })

        return categories
    }

    const isSelectedAll = (category) => {
        let isSelected = true
        headerCheckers[category].forEach(checker => {
            if(!checker.checked) {
                isSelected = false
            }
        })

        return isSelected
    }

    const onCategorySelectAll = (e, category) => {
        let isChecked = e.target.checked
        let updatedCheckers = {...headerCheckers}
        updatedCheckers[category] = updatedCheckers[category].map(checker => ({...checker, checked: isChecked}))

        setHeaderCheckers(updatedCheckers)
    }

    const onCheckerChange = (e, category, header) => {
       let updatedCheckers = {...headerCheckers}
       updatedCheckers[category] = updatedCheckers[category].map(checker => {
            let isFieldToggled = header === checker.name
            return {...checker, checked: isFieldToggled ? !checker.checked : checker.checked}
       })

        setHeaderCheckers(updatedCheckers)
    }

    const onSave = async () => {
        setErrorMessage("")
        if(!templateName) {
            setErrorMessage("Template name is required.")
            return
        }

        if(templateId === 'new') {
            create()
        } else if(templateId) {
            update()
        }
    }

    const create = async () => {
        if(isCreateTemplateLoading) {
            return
        }

        infoMessage("Creating template.")
        let response = await createReportTemplate({
            templateName,
            templateType,
            userId: id, 
            fileDetailsId,
            headers: getFormattedCheckersForSave()
        }).unwrap()

        if(response) {
            addTemplate(response)
        }
    }

    const addTemplate = (template) => {
        let updatedReportTemplates = []
        reportTemplateList.forEach(reportTemplate => {
            let updatedReportTemplate = {...reportTemplate}
            if(updatedReportTemplate.fileDetailsId === fileDetailsId) {
                updatedReportTemplate = {...updatedReportTemplate, templates: [...updatedReportTemplate.templates, template]}
            }
            updatedReportTemplates.push(updatedReportTemplate)
        })

        dispatch(setReportTemplateList(updatedReportTemplates))
    }

    const update = async () => {
        await updateReportTemplate({
            templateId,
            userId: id,
            templateName,
            headers: getFormattedCheckersForSave()
        })
    }

    const getFormattedCheckersForSave = () => {
        let checkers = []
        Object.keys(headerCheckers).forEach(category => {
            headerCheckers[category].forEach(checker => {
                checkers = [...checkers, checker]
            })
        })

        return checkers
    }

    const handleTypeChange = (type) => {
        let headerCheckers = type === 'ACCOUNT' ? accountHeaders : clientHeaders
        setHeaderCheckers(headerCheckers)
        setTemplateType(type)
    }

    return (
        <div className="report-template-container">
            {contextHolder}
            {errorMessage && 
                <div className="support-error">
                    <p>{errorMessage}</p>
                </div>
            }
            <div className="custom-report-header">
                <div className='top-header'>
                    <header>Custom Reports</header>
                </div>
                <div className='template-button-section'>
                    <button className='cancel-btn' onClick={() => navigate(`/dash/customReports`)}>Cancel</button>
                    <button className='confirm-btn' onClick={() => onSave()}>Save</button>
                </div>
            </div>
            {
                role !== 'ADVISOR' && 
                <header>Advisor: {advisor}</header>
            }
            <div className="template-header">
                <div className="template-name">
                    <div className='input-field'>
                        <input required className='input' placeholder='Custom Report Name' value={templateName} onChange={(e) => setTemplateName(e.target.value)} />
                    </div>
                </div>
                <div className="template-type">
                    <Radio.Group value={templateType} onChange={(e) => handleTypeChange(e.target.value)} disabled={templateId !== "new"}>
                        <Radio.Button className="type-button" value="ACCOUNT">Account</Radio.Button>
                        <Radio.Button className="type-button" value="CLIENT">Client</Radio.Button>
                    </Radio.Group>
                </div>
            </div>
            <div className="grid-list">
                {createCheckers()}
            </div>
        </div>
    )
}

export default CustomReportTemplate