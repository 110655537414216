import "./FormSelector.css"

import { DoubleRightOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import FormRenderer from "./FormRenderer"

import { useGetFormsMutation, useUpdateFormMutation } from "./formApiSlice"
import { useEffect, useState } from "react"
import { showSpinner, hideSpinner } from "../../components/componentSlice"
import { useDispatch, useSelector } from "react-redux"
import { useParams, useLocation } from "react-router-dom"
import { selectCurrentId } from "../auth/authSlice"

const FormSelector = () => {
    const { id } = useParams()
    const userId = useSelector(selectCurrentId)
    const location = useLocation();
    
    const [errorMessage, setErrorMessage] = useState(null)
    const [pdfForms, setPdfForms] = useState([])
    const [activeForm, setActiveForm] = useState(null)

    const dispatch = useDispatch()
    const [getForms, { 
        isLoading
     }] = useGetFormsMutation()
    const [updateForm, {
        isLoading: isUpdateFormLoading
    }] = useUpdateFormMutation()

    useEffect(() => {
        if (isLoading || isUpdateFormLoading) {
            dispatch(showSpinner())
        } else {
            dispatch(hideSpinner())
        }
    }, [isLoading, isUpdateFormLoading])

    useEffect(() => {
        async function handleGetForms(id) {
            try {
                const response = await getForms({formsId: id, userId: userId}).unwrap()
                if (response) {
                    setPdfForms(response)
                    setInitialActiveForm(response)
                }
            } catch(err) {
                if(!err.status || err.status === "FETCH_ERROR") {
                    setErrorMessage("No Server Response")
                } else {
                    setErrorMessage(err.data?.detail)
                }
            }
        }
        if (id) {
            handleGetForms(id)
        }

    }, [id])

    const getInitialFormId = () => {
        const params = new URLSearchParams(location.search)
        const value = params.get('form')
        return value
    }

    const setInitialActiveForm = (formsList) => {
        let initialFormId = getInitialFormId()
        if(initialFormId) {
            let initialForm = formsList.forms.filter(form => initialFormId === form.formId)[0]
            setActiveForm(initialForm || null)
        }
    }
    
    const createClientFormsTable = () => {
        return (
            <div className="file-table-container">
                <div className="file-table-header">
                    <header>Client Forms</header>
                </div>
                <table className="file-table">
                    <tr>
                        <th>Form Name</th>
                        <th>Form Id</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                    {pdfForms && pdfForms.forms?.length > 0 &&
                        pdfForms.forms.map((form, index) => {
                            return (
                                <tr>
                                    <td>{form.name}</td>
                                    <td>{form.formId}</td>
                                    <td>Requires Input</td>
                                    <td><Button shape="circle" icon={<DoubleRightOutlined />} onClick={() => setActiveForm(pdfForms.forms[index])} /></td>
                                </tr>
                            )
                        })
                    }
                </table>
            </div>
        )
    }

    const updateActivePdfForm = (pdfBytes) => {
        let updatedPdfForms = pdfForms.forms.map((form) => {
            let updatedForm = form
            if(form.fileName === activeForm.fileName) {
                updatedForm = {...updatedForm, 
                    form: pdfBytes
                }
            }
            return updatedForm
        })
        setPdfForms((prev) => ({...prev, forms: updatedPdfForms}))
    }

    const handleSaveForm = async (pdfBytes, pdfBlob) => {
        try {
            let request = {
                id: id,
                userId: userId,
                bundleId: pdfForms.bundleId,
                fileName: activeForm.fileName,
                form: pdfBlob
            }

            await updateForm(request)
            updateActivePdfForm(pdfBytes)
        } catch(err) {
            if(!err.status || err.status === "FETCH_ERROR") {
                setErrorMessage("No Server Response")
            } else {
                setErrorMessage(err.data?.detail)
            }
        }
    }

    return (
        <div className="forms-container">
            {errorMessage && 
                <div className="support-error">
                    <p>{errorMessage}</p>
                </div>
            }
            <div className="forms-main">
                <div className="client-forms">
                    {createClientFormsTable()}
                </div>
                <div className="form-viewer">
                    <div className="form-viewer-header">
                        <header>{activeForm?.name || "No selected form..."}</header>
                    </div>
                    {activeForm && <FormRenderer pdfData={activeForm.form} formName={activeForm.name} handleSaveForm={handleSaveForm} />}
                </div>
            </div>

        </div>
    )
}

export default FormSelector