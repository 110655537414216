import "./PasswordValidator.css"
import { hasAtLeast8Characters, hasLowerCaseCharacter, hasUpperCaseCharacter, hasNumber, hasSpecialCharacter } from "./utils/PasswordUtil"

const PasswordValidator = ( {password} ) => {
    return(
        <div className="password-validator-main">
            <div className="password-validator">
                <div className={"password-category" + (password ? hasAtLeast8Characters(password) ? "-valid" : "-invalid" : "")}>
                    <p>{(hasAtLeast8Characters(password) ? "\u2713" : "") + " should be at least 8 characters"}</p>
                </div>

                <div className={"password-category" + (password ? hasLowerCaseCharacter(password) ? "-valid" : "-invalid" : "")}>
                    <p>{(hasLowerCaseCharacter(password) ? "\u2713" : "") + " should include lowercase"}</p>
                </div>

                <div className={"password-category" + (password ? hasUpperCaseCharacter(password) ? "-valid" : "-invalid" : "")}>
                    <p>{(hasUpperCaseCharacter(password) ? "\u2713" : "") + " should include uppercase"}</p>
                </div>

                <div className={"password-category" + (password ? hasNumber(password) ? "-valid" : "-invalid" : "")}>
                    <p>{(hasNumber(password) ? "\u2713" : "") + " should include a number"}</p>
                </div>

                <div className={"password-category" + (password ? hasSpecialCharacter(password) ? "-valid" : "-invalid" : "")}>
                    <p>{(hasSpecialCharacter(password) ? "\u2713" : "") + " should include a special character"}</p>
                </div>
            </div>
        </div>
    )
}

export default PasswordValidator