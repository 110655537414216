import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: 'auth',
    initialState: {},
    reducers: {
        setCredentials: (state, action) => {
            const { id, username, role, token, passwordTemp, using2FA, verified2FA } = action.payload
            state.id = id
            state.username = username
            state.role = role
            state.token = token
            state.isPasswordTemp = passwordTemp
            state.isUsing2FA = using2FA
            state.isVerified2FA = verified2FA
        },
        setPasswordTemp: (state, action) => {
            console.log("action", action)
            state.isPasswordTemp = action?.payload
        },
        logOut: (state, action) => {
            state.token = null
        }
    }
})

export const { setCredentials, logOut, setPasswordTemp } = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state) => state?.auth

export const selectCurrentToken = (state) => state?.auth?.token

export const selectCurrentUsername = (state) => state?.auth?.username

export const selectCurrentId = (state) => state?.auth?.id

export const selectCurrentRole = (state) => state?.auth?.role

export const isUserPasswordTemp = (state) => state?.auth?.isPasswordTemp

export const isUsing2FA = (state) => state?.auth?.isUsing2FA

export const isVerified2FA = (state) => state?.auth?.isVerified2FA