import { Anchor } from 'antd'
import { getFieldCategory } from '../../template/TemplateHelper'
const FileHeaderShortcuts = ({ clientAccountsInfos, clientOrAccountsHeaderValue, handleClientOrAccountsHeaderSelect, broker }) => {

    const onShortcutChange = (id) => {
        if(id.includes('headerValue')) {
            const idArr = id.substring(id.indexOf('-') + 1)
            handleClientOrAccountsHeaderSelect(idArr)
        }
    }

    const getShortcutItems = () => {
        let shortcutItems = [{
            key: "Client Information",
            href: '#headerValue-CI',
            title: 'Client Information',
            children: clientOrAccountsHeaderValue === 'CI' ? getShortcutChildren() : []
        }]

        let accounts = clientAccountsInfos?.accounts
        if (accounts) {
            accounts.forEach((account, index) => {
                let accountInfos = account.accountInfos
                if(!accountInfos) {
                    return
                }

                let isMissingValues = accountInfos.reduce((acc, curr) => acc || !curr.value, false)
                let accountType = accountInfos.filter(field => field.fieldName === "Account Type")[0]?.value || ""
                let accountNumber = accountInfos.filter(field => field.fieldName === "Account Number")[0]?.value || ""

                shortcutItems = [...shortcutItems, {
                    key: index,
                    href: `#headerValue-${index}`,
                    title: accountType + " - " + accountNumber + (isMissingValues ? " *" : ""),
                    children: clientOrAccountsHeaderValue == index ? getShortcutChildren() : []
                }]
            })
        }
        return shortcutItems
    }

    const getShortcutChildren = () => {
        let inputs = clientOrAccountsHeaderValue === "CI" ? clientAccountsInfos?.clientInfos : clientAccountsInfos?.accounts[clientOrAccountsHeaderValue]?.accountInfos

        let shortcutSectionHeaders = new Set()
        let fieldCategory = getFieldCategory(broker)
        if(inputs) {
            inputs.forEach(input => {
                let isOthers = true
                Object.keys(fieldCategory).forEach(header => {
                    if(fieldCategory[header].includes(input.fieldName.trim())) {
                        shortcutSectionHeaders.add(header)
                        isOthers = false
                    }
                })
                if(isOthers) {
                    shortcutSectionHeaders.add("Other Information")
                }

            })
        }
        
        let shortcutChildren = []
        for (const header of shortcutSectionHeaders) {
            shortcutChildren = [...shortcutChildren, {
                key: header,
                href: '#' + header,
                title: header,
              }]
        }
        return shortcutChildren
    }

    return(
        <Anchor
            offsetTop={0}
            onChange={(e) => onShortcutChange(e)}
            items={getShortcutItems()}
        />
    )
}
export default FileHeaderShortcuts