import moment from "moment"

export const isEmailAddressValid = (emailAddress) => {
    return String(emailAddress)
        .toLowerCase()
        .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
}

export const formatDate = (date) => {
    let validDateValue
    let validFormat = "YYYY-MM-DD"
    if(moment(date, validFormat).format(validFormat) === date) {
        validDateValue = date
    } else {
        validDateValue = moment(date, "DD/MM/YYYY").format(validFormat)
    }
    return validDateValue
}

export const formatDollarAmount = (value) => {
    if(value && value.trim()) {
        let formattedValue = value.replaceAll("$", "").replaceAll(",", "")

        if(!isNaN(formattedValue)) {
            formattedValue = parseFloat(formattedValue).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 9})
            value =  "$" + (formattedValue.includes(".") ? formattedValue : (formattedValue + (".00")))
        }
    }
    return value
}

export const isNumber = (value) => {
    let formattedValue = value.replaceAll(",", "").replaceAll("$", "")
    return formattedValue && !isNaN(formattedValue)
}

export const formatNumber = (value) => {
    let isMoney = value.includes("$")
    let isDecimal = value.endsWith(".")
    let formattedValue = value.replaceAll(",", "").replaceAll("$", "")

    return (isMoney ? "$" : "") + parseFloat(formattedValue).toLocaleString('en', {minimumFractionDigits: 0, maximumFractionDigits: 9}) + (isDecimal ? "." : "")
}

export const downloadFile = (file, filename) => {
    var hiddenElement = document.createElement('a');
    var url = window.URL || window.webkitURL;
    var blob = url.createObjectURL(file);
    hiddenElement.href = blob;
    hiddenElement.target = '_blank';
    hiddenElement.download = filename;
    hiddenElement.click();
}