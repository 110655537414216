import { getFieldTypes } from "../../template/TemplateHelper"
import { AddressAutofill } from "@mapbox/search-js-react"
import { formatDate } from "../../../utils/Utilities"

const InputField = ({ infoField, broker, handleInputChange, clientOrAccountsHeaderValue, clientOptions }) => {

    const createField = () => {
        let fieldName = infoField.fieldName
        let value = infoField.value || ""
        let fieldTypes = getFieldTypes(broker)
        let type = fieldTypes[fieldName] ? fieldTypes[fieldName].type : "input"
        let required = fieldTypes[fieldName]?.required
    
        let field
        if(type === "select") {
            field = 
                <div className="input-field">
                    <label>{fieldName}</label>
                    <select className={!value && required ? "no-value" : ""} 
                        required={required} 
                        value={value} 
                        onChange={(e) => handleInputChange(clientOrAccountsHeaderValue, fieldName, e.target.value)} 
                        autoComplete={fieldTypes[fieldName]?.autoComplete}
                        style={fieldTypes[fieldName]?.width ? {width: fieldTypes[fieldName]?.width} : {width: "250px"}}>
                            <option disabled selected value=""> -- select an option -- </option>
                            {fieldTypes[fieldName].options.map(option => <option value={option.value}>{option.label}</option>)}
                    </select>
                </div>
        } else if(type === "address") {
            field = 
                <div className="input-field">
                    <label>{fieldName}</label>
                    <AddressAutofill accessToken={process.env.REACT_APP_MAP_BOX_TOKEN}>
                        <input
                            required={required} 
                            className={!value && required ? "no-value" : ""}
                            autoComplete={fieldTypes[fieldName]?.autoComplete}
                            value={value} 
                            onChange={(e) => handleInputChange(clientOrAccountsHeaderValue, fieldName, e.target.value)} 
                            style={fieldTypes[fieldName]?.width ? {width: fieldTypes[fieldName]?.width} : {width: "250px"}}
                        />
                    </AddressAutofill>
                </div>   
        } else if(type === "date") {
            let validDateValue = formatDate(value)
            field =
                <div className="input-field">
                    <label>{fieldName}</label>
                    <input className={!value && required ? "no-value" : ""} 
                        required={required} 
                        value={validDateValue} 
                        style={fieldTypes[fieldName]?.width ? {width: fieldTypes[fieldName]?.width} : {width: "250px"}}
                        type='date'
                        onChange={(e) => handleInputChange(clientOrAccountsHeaderValue, fieldName, e.target.value)} 
                    />
                </div>
        } else if(type === "clientSelect") {
            field = 
                <div className="input-field">
                    <label>{fieldName}</label>
                    <select className={!value && required ? "no-value" : ""} 
                        required={required} 
                        value={value} 
                        onChange={(e) => handleInputChange(clientOrAccountsHeaderValue, fieldName, e.target.value)} 
                        autoComplete={fieldTypes[fieldName]?.autoComplete}
                        style={fieldTypes[fieldName]?.width ? {width: fieldTypes[fieldName]?.width} : {width: "250px"}}>
                            <option disabled selected value=""> -- select an option -- </option>
                            {clientOptions.map(option => <option value={option.name}>{option.name}</option>)}
                    </select>
                </div>
        } else {
            field =
                <div className="input-field">
                    <label>{fieldName}</label>
                    <input className={!value && required ? "no-value" : ""} 
                        required={required} 
                        maxLength={fieldTypes[fieldName]?.maximumLength} 
                        autoComplete={fieldTypes[fieldName]?.autoComplete} 
                        value={value} 
                        style={fieldTypes[fieldName]?.width ? {width: fieldTypes[fieldName]?.width} : {width: "250px"}}
                        onChange={(e) => handleInputChange(clientOrAccountsHeaderValue, fieldName, e.target.value)} 
                    />
                </div>
        }
        return field
    }

    return (
        <>
            {createField()}
        </>
    )

}

export default InputField