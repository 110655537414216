import "./SupportStaff.css"
import { DoubleRightOutlined } from '@ant-design/icons'
import { Button, Empty } from 'antd'
import { useEffect, useState } from "react"
import { useGetConnectedAdvisorsMutation } from "../../user/userApiSlice"
import { useSelector, useDispatch } from 'react-redux'
import { selectCurrentId } from "../../auth/authSlice"
import { useNavigate } from "react-router-dom"
import { selectConnectedAdvisors, setSupportStaffAdvisors } from "./supportStaffSlice"
import { showSpinner, hideSpinner } from "../../../components/componentSlice"


const SupportStaff = () => {
    const id = useSelector(selectCurrentId)
    const advisors = useSelector(selectConnectedAdvisors)

    const [errorMessage, setErrorMessage] = useState("")
    const [connectedAdvisors, setConnectedAdvisors] = useState([])
    const [isConnectedAdvisorsLoaded, setConnectedAdvisorsLoaded] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [ getConnectedAdvisors,  {
        isLoading,
        isError,
        error
    }] = useGetConnectedAdvisorsMutation()

    useEffect(() => {
        async function handleGetConnectedAdvisors() {
          const response = await getConnectedAdvisors(id).unwrap()
          if(response) {
            setConnectedAdvisors(response)
            dispatch(setSupportStaffAdvisors(response))
            setConnectedAdvisorsLoaded(true)
          }
        }
        handleGetConnectedAdvisors()
      }, [id])

    useEffect(() => {
        if(advisors) {
            setConnectedAdvisors(advisors)
            setConnectedAdvisorsLoaded(true)
        } 
      }, [advisors])

    useEffect(() => {
        if(isError) {
            setErrorMessage(error)
        }
    }, [isError])

    useEffect(() => {
        if(isLoading && !advisors) {
            dispatch(showSpinner())
        } else{
            dispatch(hideSpinner())
        }
    }, [isLoading])

    const getTable = () => {
        return connectedAdvisors && connectedAdvisors.length > 0 ?
            <>
                <tr>
                    <th>Advisor</th>
                    <th>Last Updated By</th>
                    <th>Last Updated</th>
                    <th></th>
                </tr>
                {
                    connectedAdvisors.map(advisor => {
                        return (
                            <tr>
                                <td>{advisor.advisorName}</td>
                                <td>{advisor.lastUpdatedBy + " - " + advisor.lastUpdatedByRole}</td>
                                <td>{advisor.lastUpdated}</td>
                                <td><Button shape="circle" icon={<DoubleRightOutlined />} onClick={() => navigate(`/dash/fileDetails/${advisor.fileDetailsId}`)} /></td>
                            </tr>
                        )
                    })
                }
                
            </>
            :
            isConnectedAdvisorsLoaded && <Empty description={"You are not yet connected to an Advisor or the Advisor doesn't have a template yet."} />
    }

    return (
        <div className="table-page-container">
            {errorMessage && 
                <div className="support-error">
                    <p>{errorMessage}</p>
                </div>
            }
            <div className="support-header">
                <div className='top-header'>
                    <header>Advisors</header>
                </div>
            </div>
            
            <div className="support-table-container">
                <table className={"file-table" + (connectedAdvisors.length > 0 ? "" : " empty")}>
                    {getTable()}
                </table>
            </div>
        </div>
    )
}

export default SupportStaff