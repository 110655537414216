import { apiSlice } from "../../app/api/apiSlice";
import { logOut, setCredentials } from "./authSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: credentials => ({
                url: '/login',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        sendLogout: builder.mutation({
            query: () => ({
                url: '/logoutUser',
                method: 'POST'
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                try{
                    const { data } = await queryFulfilled
                    dispatch(logOut())
                    setTimeout(() => {
                        dispatch(apiSlice.util.resetApiState())
                    }, 1000)
                } catch (err) {
                    console.log(err)
                }
            }
        }),
        refresh: builder.mutation({
            query: () => ({
                url: '/refresh',
                method: 'GET'
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                try{
                    const { data } = await queryFulfilled
                    console.log("refresh", data)
                    dispatch(setCredentials(data))

                } catch (err) {
                    console.log(err)
                }
            }
        }),
        generate2faQrCode: builder.mutation({
            query: id => ({
                url: '/mfa/generate2faQrCode/' + id,
                method: 'GET'
            })
        }),
        verification2fa: builder.mutation({
            query: verification2faRequest => ({
                url: '/mfa/verification2fa/' + verification2faRequest.id,
                method: 'POST',
                body: verification2faRequest.code
            })
        }),
    })
})

export const { 
    useLoginMutation,
    useSendLogoutMutation,
    useRefreshMutation,
    useGenerate2faQrCodeMutation,
    useVerification2faMutation
} = authApiSlice