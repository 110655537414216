import "./FormRenderer.css"

import { useEffect, useState } from "react"

import { PDFDocument } from 'pdf-lib'
import { Document, Page, pdfjs } from 'react-pdf'

import { DownloadOutlined, SaveOutlined } from '@ant-design/icons'
import { Button } from "antd"
import { MinusOutlined, PlusOutlined } from "@ant-design/icons"

import "react-pdf/dist/esm/Page/TextLayer.css"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"

import { PDFTextField, PDFCheckBox} from "pdf-lib"

const FormRenderer = ({ pdfData, formName, handleSaveForm }) => {

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`

    const [file, setFile] = useState(null)
    const [pages, setPages] = useState([])

    const [formFields, setFormFields] = useState([])
    const [formValues, setFormValues] = useState({})
    const [pageHeight, setPageHeight] = useState(0)
    const [pageWidth, setPageWidth] = useState(0)
    const [numPages, setNumPages] = useState(null)
    const [scale, setScale] = useState(1.5)

    useEffect(() => {
        async function loadPdf() {
            if (pdfData) {
                const pdfDoc = await PDFDocument.load(pdfData)

                if (pdfDoc) {
                    const pdfBytes = await pdfDoc.save()
                    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
                    const url = URL.createObjectURL(pdfBlob);

                    setFile(url)
                    setPages(Array.from({ length: pdfDoc.getPageCount() }, (_, i) => i + 1))
                }
            }
        }
        loadPdf()
    }, [pdfData])

    const ptToPx = (points) => {
      const pixels = points * (96 / 72)
      return pixels
    }

    useEffect(() => {
      extractFormFields()
    }, [pdfData, scale])

    const extractFormFields = async () => {
      const pdfDoc = await PDFDocument.load(pdfData)
      if (!pdfDoc) {
        return
      }

      try {
          const pages = pdfDoc.getPages()
          const firstPage = pages[0]
          const { width, height } = firstPage.getSize()

          const form = pdfDoc.getForm()

          // Get all the form fields (e.g., text fields)
          const fields = form.getFields()
          const fieldData = []
          let formFieldValues = {}

          fields.forEach((field) => {
          const name = field.getName()
          const fieldType = getFieldType(field)
          
          const widgets = field.acroField.getWidgets() // Get widgets for the field

          widgets.forEach(widget => {
            const rect = widget.getRectangle() // Get rectangle data
            let pageIndex = pdfDoc.getPages().findIndex((p) => p.ref === widget.P())

            // Check if the rect is valid and extract its properties
            if (rect && rect.x !== undefined && rect.y !== undefined) {
              fieldData.push({
                name,
                rect: {
                  x: ptToPx(rect.x * scale),
                  y: ptToPx(rect.y * scale),
                  width: ptToPx(rect.width * scale),
                  height: ptToPx(rect.height * scale),
                },
                fieldType,
                pageIndex
              })

              if (fieldType === 'PDFTextField') {
                // Text field
                formFieldValues = {
                  ...formFieldValues,
                  [name]: formValues[name] || field.getText()
                }
              } else if (fieldType === 'PDFCheckBox') {
                // Checkbox
                formFieldValues = {
                  ...formFieldValues,
                  [name]: formValues[name] || (field.isChecked() ? "true" : "false")
                }
              }
            } else {
              console.warn(`Invalid rectangle data for field: ${name}`)
            }
          })
        })
   
        setFormFields(fieldData)
        setPageHeight(ptToPx(height * scale))
        setPageWidth(ptToPx(width))
        setFormValues(formFieldValues)
      } catch (error) {
        console.error('Error extracting form fields:', error)
      }
    }

    const onLoadSuccess = ({ numPages }) => {
        setNumPages(numPages)
    }

    const handleFieldChange = (fieldName, value, type) => {
      let fieldValue = value
      if(type === 'PDFCheckBox') {
        fieldValue = value ? "true" : "false"
      }

      setFormValues((prevValues) => ({
        ...prevValues,
        [fieldName]: fieldValue,
      }))
    }

    const createField = (field) => {
      if (field.fieldType === 'PDFTextField') {
        return (
          <input style={{width: '100%'}} 
            value={formValues[field.name] || ''}
            onChange={(e) => handleFieldChange(field.name, e.target.value, 'PDFTextField')}
          />
        )
      } else if (field.fieldType === 'PDFCheckBox') {
          return (
            <input style={{width: '100%'}} 
              type="checkbox" id={field.name}
              checked={formValues[field.name] === "true"}
              onChange={(e) => handleFieldChange(field.name, e.target.checked, 'PDFCheckBox')}
            />
          )
      } else {
        console.log('Unknown field type:', field.fieldType)
      }
    }

    const downloadPdf = () => {
      if (file && file.length > 0) {
        const link = document.createElement('a')
        link.href = file
        link.download = `${formName}.pdf`
        link.click()
      }
    }

    const getFieldType = (field) => {
      let fieldType
      if(field instanceof PDFTextField) {
        fieldType = 'PDFTextField'
      } else if(field instanceof PDFCheckBox) {
        fieldType = 'PDFCheckBox'
      }

      return fieldType
    }

    const savePdf = async () => {
      if (!pdfData) {
        return
      }

      const pdfDoc = await PDFDocument.load(pdfData)
      if (pdfDoc) {
        const form = pdfDoc.getForm()

        // Get all the form fields (e.g., text fields)
        const fields = form.getFields()

        fields.forEach((field) => {
          const name = field.getName()
          const fieldType = getFieldType(field)
          const widgets = field.acroField.getWidgets() // Get widgets for the field

          if(widgets && widgets.length > 0) {
            if (fieldType === 'PDFTextField') {
              // Text field
              field.setText(formValues[name])
            } else if (fieldType === 'PDFCheckBox') {
              if(formValues[name] === "true") {
                field.check()
              } else {
                field.uncheck()
              }
            }
          }
        })

        const pdfBytes = await pdfDoc.save()
        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' })
        const url = URL.createObjectURL(pdfBlob)

        setFile(url)
        handleSaveForm(pdfBytes, pdfBlob)
    }
  }
  

    return (
        <div>
            {file && pages?.length > 0 &&
                <div className="pdf-container">
                    <div className="pdf-utils">
                        <div className="zoom-control">
                            <Button icon={<MinusOutlined />} disabled={scale < 1} onClick={() => setScale((prevScale) => prevScale - 0.1)} />
                            <label>{`${Math.ceil(scale * 100)}%`}</label>
                            <Button icon={<PlusOutlined />} disabled={scale > 1.4} onClick={() => setScale((prevScale) => prevScale + 0.1)} />
                        </div>
                        <button onClick={() => downloadPdf()}>
                          <span>Download</span>
                          <DownloadOutlined />
                        </button>
                        <button onClick={() => savePdf()}>
                          <span>Save</span> 
                          <SaveOutlined />
                        </button>
                    </div>
                    <div className="pdf-main">
                        <div className="pdf-validations">
                            {/* <Tag className="validation-tag" color="error">SSN Required</Tag>
                            <Tag className="validation-tag" color="error">Sample</Tag>
                            <Tag className="validation-tag" color="warning">Sample</Tag>
                            <Tag className="validation-tag" color="warning">Sample</Tag>
                            <Tag className="validation-tag" color="error">Sample</Tag> */}
                        </div>
                        <div className="pdf">
                            <Document file={file} onLoadSuccess={onLoadSuccess}>
                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page
                                        key={index}
                                        pageNumber={index + 1}
                                        width={pageWidth}
                                        scale={scale}
                                    />
                                ))}
                            </Document>

                            <div style={{position: 'relative'}}>
                              {formFields
                                .map(field => {
                                  return (
                                    <div style={{
                                      position: 'absolute',
                                      top: `${(pageHeight * (numPages - field.pageIndex) * -1) + (pageHeight - (field.rect.y + field.rect.height))}px`, // Correct Y-axis positioning
                                      left: `${field.rect.x}px`,
                                      width: `${field.rect.width}px`,
                                      height: `${field.rect.height}px`,
                                      zIndex:10
                                    }}>
                                      {createField(field)}
                                    </div>
                                  )
                                })
                              }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )

}

export default FormRenderer