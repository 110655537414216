import { useState, useEffect, useRef } from 'react'
import { selectCurrentId, selectCurrentUsername, setCredentials, selectCurrentRole } from "../authSlice"
import { useVerification2faMutation } from '../authApiSlice'
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { showSpinner, hideSpinner } from '../../../components/componentSlice'
import { useGetFileByAdvisorNameMutation } from '../../file/fileApiSlice'
import { setFile } from '../../file/fileSlice'


const Verify2faForm = () => {
    const [code, setCode] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const id = useSelector(selectCurrentId)
    const username = useSelector(selectCurrentUsername)
    const role = useSelector(selectCurrentRole)

    const [verification2fa, { isLoading }] = useVerification2faMutation()
    const [getFileByAdvisorName, { isLoading: isGetFileLoading }] = useGetFileByAdvisorNameMutation()
    const codeInput = useRef()

    useEffect(() => {
        if(codeInput.current) {
            codeInput.current.focus()
        }
    }, [codeInput.current])

    useEffect(() => {
        if(isLoading || isGetFileLoading) {
            dispatch(showSpinner())
        } else{
            dispatch(hideSpinner())
        }
    }, [isLoading, isGetFileLoading])

    const onSubmit = async (e) => {
        e.preventDefault()
        setErrorMessage("")

        try {
            const response = await verification2fa({code, id})
            console.log('response', response)
            if(response?.data?.user) {
                dispatch(setCredentials(response.data?.user))
                setCode("")
                navigateAfterLogin()
            } else if(response?.error?.status === 400) {
                setErrorMessage("That code is invalid or expired. Try another.")
            }
        } catch(err) {
            console.log('erro',err)
            if(!err.status) {
                setErrorMessage("No Server Response")
            } else if(err.status === "FETCH_ERROR") {
                setErrorMessage("No Server Response")
            } else if(err.status === 400 || err.status === 401) {
                setErrorMessage("That code is invalid or expired. Try another.")
            } else {
                setErrorMessage(err.data?.message)
            }
        }
    }

    const navigateAfterLogin = async () => {
        if(role === "ADVISOR") {
            const file = await getFileByAdvisorName(username).unwrap()
            console.log(file)
            if(file) {
                dispatch(setFile(file))
                navigate(`/dash/fileDetails/${file.id}`)
            } else {
                navigate(`/dash/fileDetails/${0}`)
            }
        }
        else if (role === "SUPPORT_STAFF") {
            navigate("/dash/support")
        } else {
            navigate("/dash")
        }
    }

    const onCancel = () => {
        navigate("/logout")
    }

    return (
        <>
            <div className='top-header'>
                <header>Verify</header>
            </div>
            <div className='input-field'>
                <p>Enter the code generated by your authenticator app.</p>
            </div>
            <form onSubmit={(e) => onSubmit(e)}>
                <div className='input-field'>
                    <input required className='input' placeholder='Verication Code' value={code} onChange={(e) => setCode(e.target.value)} autofocus ref={codeInput} />
                </div>
                <p className='errorMessage'>{errorMessage}</p>
                <div className='verify-button-section'>
                    <button className='cancel-btn' type="button" onClick={() => onCancel()}>Cancel</button>
                    <button className='confirm-btn' type="submit">Verify</button>
                </div>
            </form>
        </>
    )
}
export default Verify2faForm