import { PlusOutlined, CloseOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import SearchableDropdown from "../../components/SearchableDropdown"
import { useState, useEffect } from 'react'
import { useGetAdvisorUsersMutation } from "./userApiSlice"

const SupportStaffAdvisorPicker = ({ handleAddAdvisor, handleRemoveAdvisor, updatedConnectedAdvisors }) => {
    const [searchValue, setSearchValue] = useState("")
    const [advisors, setAdvisors] = useState([])
    const [toBeAddedAdvisor, setToBeAddedAdvisor] = useState(null)
    const [pickerErrorMessage, setPickerErrorMessage] = useState(null)

    const [ getAdvisorUsers,  {
    }] = useGetAdvisorUsersMutation()

    useEffect(() => {
        async function getAdvisors() {
          const response = await getAdvisorUsers()
          setAdvisors(response.data)
        }
        getAdvisors()
      }, [])

    const getAdvisorsOptions = () => {
        let connectedAdvisorIds = updatedConnectedAdvisors?.map(advisor => advisor.id)
        return advisors?.filter(user => !connectedAdvisorIds.includes(user.id)).map(user => ({id: user.id, name: user.username}))
    }

    const addAdvisor = () => {
        let connectedAdvisors = [...updatedConnectedAdvisors]
        if(connectedAdvisors?.map(advisor => advisor.id).includes(toBeAddedAdvisor.id)) {
            setPickerErrorMessage("Advisor is already added.")
            return
        }

        handleAddAdvisor(toBeAddedAdvisor)
        setSearchValue("")
        setToBeAddedAdvisor(null)
        setPickerErrorMessage(null)
    }

    const removeAdvisor = (id) => {
        handleRemoveAdvisor(id)
    }

    const onChangeSearch = async (val, id) => {
        if(id) {
            setSearchValue(val)
            setToBeAddedAdvisor({id, name: val})
        }
    }

    const getTable = () => {
        return (
            <>
                <tr>
                    <th>Advisors</th>
                    <th></th>
                </tr>
                {
                    updatedConnectedAdvisors?.map(user => {
                        return (
                            <tr>
                                <td>{user.name}</td>
                                <td><Button shape="circle" danger icon={<CloseOutlined />} onClick={() => removeAdvisor(user.id)} /></td>
                            </tr>
                        )
                    })
                }
                <tr>
                    <td>
                        <SearchableDropdown
                            options={getAdvisorsOptions()}
                            label="name"
                            id="id"
                            placeholderVal={"Select Advisor..."}
                            selectedVal={searchValue}
                            handleChange={(val, id) => onChangeSearch(val, id)}
                        />
                    </td>
                    <td><Button shape="circle" type="primary" disabled={!toBeAddedAdvisor} icon={<PlusOutlined />} onClick={() => addAdvisor()} /></td>
                </tr>
            </>
        )
    }

    return (
        <>
            {pickerErrorMessage && 
                <div className="errorMessage">
                    <p>{pickerErrorMessage}</p>
                </div>
            }
            <div className="support-table-container">
                <table className="support-user-table">
                    {getTable()}
                </table>
            </div>
        </>
    )
}

export default SupportStaffAdvisorPicker