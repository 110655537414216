import { useState, useEffect } from "react"
import { useGetSupportStaffUsersMutation, useUpdateConnectionMutation } from "./userApiSlice"
import { useDispatch } from 'react-redux'
import { showSpinner, hideSpinner } from "../../components/componentSlice"
import SearchableDropdown from "../../components/SearchableDropdown"
import { message } from 'antd'
import SupportStaffAdvisorPicker from "./SupportStaffAdvisorPicker"

const ManageSupportStaff = () => {

    const [selectedSupportStaffUser, setSelectedSupportStaffUser] = useState(null)
    const [supportStaffUsers, setSupportStaffUsers] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [updatedConnectedAdvisors, setUpdatedConnectedAdvisors] = useState([])

    const [errorMessage, setErrorMessage] = useState(null)

    const [messageApi, contextHolder] = message.useMessage()
    const dispatch = useDispatch()

    const [ updateConnection,  {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateConnectionMutation()

    const [ getSupportStaffUsers,  {
        isLoading: isSupportUsersLoading,
        isSuccess: isSupportUsersSuccess,
        isError: isSupportUsersError,
        error: supportUsersError
    }] = useGetSupportStaffUsersMutation()

    useEffect(() => {
        if(isLoading) {
            dispatch(showSpinner())
        } else{
            dispatch(hideSpinner())
        }
    }, [isLoading])

    const alertMessage = (message) => {
        messageApi.success(message);
    }

    useEffect(() => {
        async function getSupportStaffUserList() {
          const response = await getSupportStaffUsers()
          setSupportStaffUsers(response.data)
        }
        getSupportStaffUserList()
    }, [])

    useEffect(() => {
        if(selectedSupportStaffUser?.connectedAdvisors) {
            setUpdatedConnectedAdvisors(selectedSupportStaffUser.connectedAdvisors)
        } else {
            setUpdatedConnectedAdvisors([])
        }
    }, [selectedSupportStaffUser])

    const handleAddAdvisor = (advisor) => {
        let connectedAdvisors = [...updatedConnectedAdvisors]
        connectedAdvisors.push(advisor)
        setUpdatedConnectedAdvisors(connectedAdvisors)
    }

    const handleRemoveAdvisor = (id) => {
        let connectedAdvisors = [...updatedConnectedAdvisors]
        let updated = connectedAdvisors.filter(advisor => id !== advisor.id)

        setUpdatedConnectedAdvisors(updated)
    }

    const getSupportStaffUsersOptions = () => {
        return supportStaffUsers?.map(user => ({id: user.id, name: user.username}))
    }

    const onChangeSearch = async (val, id) => {
        if(id) {
            setSelectedSupportStaffUser(supportStaffUsers.filter(user => user.id === id)[0])
            setSearchValue(val)
        }
    }

    const save = async () => {
        setErrorMessage("")
        
        try {
            let response = await updateConnection({connections: updatedConnectedAdvisors, id: selectedSupportStaffUser.id})
            if(response?.data) {
                updateSupportStaffUser(response.data)
            }

            alertMessage("Updated.")
        } catch(err) {
            if(!err.status) {
                setErrorMessage("No Server Response");
            } else if(err.status === 400 || err.status === 401) {
                setErrorMessage("Incorrect Username or Password")
            } else {
                setErrorMessage(err.data?.message)
            }
        }
    }

    const updateSupportStaffUser = (updatedUser) => {
        let updatedSupportStaffUsers = [...supportStaffUsers]

        let index = -1
        for (let i = 0; i < supportStaffUsers.length; i++) {
            if (supportStaffUsers[i].id === selectedSupportStaffUser.id) {
                index = i
                break
            }
        }

        if (index > -1) {
            updatedSupportStaffUsers.splice(index, 1)
        }

        updatedSupportStaffUsers.push(updatedUser)
        setSupportStaffUsers(updatedSupportStaffUsers)
        setSelectedSupportStaffUser(updatedUser)
    }

    const isConnectionLatest = () => {
        let isLatest = true
        if (selectedSupportStaffUser?.connectedAdvisors?.length !== updatedConnectedAdvisors?.length) {
            isLatest = false
        }

        let connectedAdvisorIds = selectedSupportStaffUser?.connectedAdvisors?.map(advisor => advisor.id)

        for (var i = 0; i < updatedConnectedAdvisors.length; ++i) {
            if (!connectedAdvisorIds?.includes(updatedConnectedAdvisors[i].id)) {
                isLatest = false
                break
            }
        }

        return isLatest
    }

    return (
        <div className="support-user">
            {contextHolder}
            <div className='top-header'>
                <header>Support Staff</header>
            </div>
            {errorMessage && 
                <div className="errorMessage">
                    <p>{errorMessage}</p>
                </div>
            }
            <div className="support-body">
                <div className="reset-search">
                    <SearchableDropdown
                        options={getSupportStaffUsersOptions()}
                        label="name"
                        id="id"
                        placeholderVal={"Select Support Staff..."}
                        selectedVal={searchValue}
                        handleChange={(val, id) => onChangeSearch(val, id)}
                    />
                </div>
                {selectedSupportStaffUser && 
                    <>
                        <SupportStaffAdvisorPicker 
                            handleAddAdvisor={handleAddAdvisor} 
                            handleRemoveAdvisor={handleRemoveAdvisor}
                            updatedConnectedAdvisors={updatedConnectedAdvisors} />
                        <div className='input-field'>
                            <button disabled={isConnectionLatest()} onClick={() => save()}>Save</button>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default ManageSupportStaff