import "./ManageUser.css"
import { useState, useEffect } from "react"
import CreateUser from "./CreateUser"
import ResetUser from "./ResetUser"
import { Layout, Menu } from 'antd'

import {
    UserAddOutlined,
    RollbackOutlined,
    TeamOutlined,
  } from '@ant-design/icons';
import ManageSupportStaff from "./ManageSupportStaff"

const ManageUser = () => {
    const { Sider } = Layout
    const [selectedKey, setSelectedKey] = useState("1")

    const getItem = (label, key, icon, children) => {
        return {
          key,
          icon,
          children,
          label,
        }
    }

    const items = [
        getItem('New User', '1', <UserAddOutlined />),
        getItem('Reset User', '2', <RollbackOutlined />),
        getItem('Support Staff', '3', <TeamOutlined />),
    ]

    return(
        <Layout style={{minHeight: '100vh'}}>
            <Sider
                theme="light"
                breakpoint="lg"
                collapsedWidth="0"
                onBreakpoint={(broken) => {
                console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                console.log(collapsed, type);
                }}
                onChange={(e) => console.log(e)}
                style={{borderRight: '1px solid lightgray'}}
            >
                <div className="demo-logo-vertical" style={{padding: '20px',}}/>
                <Menu theme="light" mode="inline" defaultSelectedKeys={[selectedKey]} items={items} onClick={(e) => setSelectedKey(e.key)} />
            </Sider>
            <div className="user-main">
                <div className="user-container">
                    {selectedKey === "1" && <CreateUser />}
                    {selectedKey === "2" && <ResetUser />}
                    {selectedKey === "3" && <ManageSupportStaff />}
                </div>
            </div>
        </Layout>
    )
}

export default ManageUser