import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { setCredentials } from './authSlice'
import { useLoginMutation } from './authApiSlice'
import { showSpinner, hideSpinner } from '../../components/componentSlice'
import './Login.css'

const Login = () => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [login, { isLoading }] = useLoginMutation()

    useEffect(() => {
        setErrorMessage("")
    }, [username, password])

    const onSubmit = async (e) => {
        e.preventDefault()
        setErrorMessage("")
        try {
            const loginResponse = await login({ username, password }).unwrap()
            console.log("loginResponse", loginResponse)
            if(loginResponse && loginResponse["user"]) {
                dispatch(setCredentials(loginResponse["user"]))
                setUsername("")
                setPassword("")
                navigateAfterLogin()
            } else if(loginResponse && loginResponse["error"]) {
                setErrorMessage(loginResponse["error"]);
            }
        } catch(err) {
            console.log(err)
            if(!err.status) {
                setErrorMessage("No Server Response")
            } else if(err.status === "FETCH_ERROR") {
                setErrorMessage("No Server Response")
            } else if(err.status === 400 || err.status === 401) {
                setErrorMessage("Incorrect Username or Password")
            } else {
                setErrorMessage(err.data?.message)
            }
        }
    }

    const navigateAfterLogin = async () => {
        navigate("/verify")
    }

    useEffect(() => {
        if(isLoading) {
            dispatch(showSpinner())
        } else{
            dispatch(hideSpinner())
        }
    }, [isLoading])

    return (
        <>
            <nav className="login-welcome-nav">
                    <div className="login-logo">Reserve CRM</div>
            </nav>
            <div className="login-box">
                <div className='login-container'>
                    <div className='top-header'>
                        <header>Sign in</header>
                    </div>
                    <p className='errorMessage'>{errorMessage}</p>
                    <form onSubmit={(e) => onSubmit(e)}>
                        <div className='input-field'>
                            <input required className='input' placeholder='Username' value={username} onChange={(e) => setUsername(e.target.value)} />
                        </div>
                        <div className='input-field'>
                            <input required className='input' placeholder='Password' type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <div className='input-field'>
                            <button type="submit">Sign In</button>
                        </div>
                        <div className='password-forget-email'>
                            <p>If you forgot your password please email info@beyondadvising.com</p>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default Login