import { useSendLogoutMutation } from "./authApiSlice"
import { useDispatch } from "react-redux"
import { showSpinner, hideSpinner } from "../../components/componentSlice"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"

const Logout = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [sendLogout, {
        isLoading
    }] = useSendLogoutMutation()

    useEffect(() => {
        if(isLoading) {
            dispatch(showSpinner())
        } else{
            dispatch(hideSpinner())
        }
    }, [isLoading])

    useEffect(() => {
        async function logout() {
          await sendLogout()
          navigate('/')
        }
        logout()
      }, [])
}
export default Logout