import { Result } from 'antd'

const Error403Page = () => {
    return (
        <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized."
        />
    )
}
export default Error403Page