import { useLocation, Navigate, Outlet } from "react-router-dom"
import { isVerified2FA, selectCurrentUser } from "../authSlice"
import { useSelector } from "react-redux"

const Require2FA = () => {
    const location = useLocation()
    const username = useSelector(selectCurrentUser)
    const verified = useSelector(isVerified2FA)
    console.log("verified", verified)

    let content
    if(username && !verified) {
        content = <Navigate to="/verify" state={{ from: location }} replace />
    } else if (username && verified) {
        content = <Outlet />
    } else {
        content = <Navigate to="/" state={{ from: location }} replace />
    }

    return content
}

export default Require2FA