import { useLocation, Navigate, Outlet } from "react-router-dom"
import { isUserPasswordTemp } from "./authSlice"
import { useSelector } from "react-redux"

const RequirePasswordChange = () => {

    const location = useLocation()
    const isPasswordTemp = useSelector(isUserPasswordTemp)

    return isPasswordTemp ? <Navigate to="/dash/passwordChange" state={{ from: location }} replace /> : <Outlet />
}

export default RequirePasswordChange